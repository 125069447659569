/**
 * The Understand Capacity modal for modifying primary care numbers.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';

import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import { getInHousePrimaryCareSelector } from 'Modules/UnderstandCapacity/Services/selector';
import {
    canEditVISN,
    getCurrentMarketName,
    getDefaultYearObject,
    getMarketList
} from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { Form, formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form';

import PrimaryCareCapacityModalColumnDefs from './TableOutline';

class UCHRCPrimaryCareModal extends React.Component {
    static propTypes = {
        primaryCare: PropTypes.shape({
            list: PropTypes.arrayOf(
                PropTypes.shape({
                    edited: PropTypes.bool,
                    PanelizedEnrollees: PropTypes.number,
                    PreviousPanelizedEnrollees: PropTypes.number,
                    PanelCapacity: PropTypes.number,
                    PreviousPanelCapacity: PropTypes.number,
                    RvuProductionCapacityNationalAverage: PropTypes.number,
                    PreviousRvuProductionCapacityNationalAverage: PropTypes.number,
                    PreviPreviousRvuProductionCapacityNationalAverageousCapacity: PropTypes.number
                })
            )
        }).isRequired,
        currentFacility: PropTypes.string.isRequired,
        currentHspc: PropTypes.string.isRequired,
        marketName: PropTypes.string.isRequired,
        defaultYear: PropTypes.shape({}).isRequired,
        updatePrimaryCare: PropTypes.func.isRequired,
        togglePrimaryCareModal: PropTypes.func.isRequired,
        clearPrimaryCareModal: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        error: PropTypes.string,
        canEdit: PropTypes.bool,
        pristine: PropTypes.bool,
        submitting: PropTypes.bool,
        modal: PropTypes.shape({
            show: PropTypes.bool,
            saving: PropTypes.bool
        }).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    static defaultProps = {
        error: '',
        canEdit: false,
        pristine: true,
        submitting: false
    };

    constructor(props) {
        super(props);
        this.state = { fieldsEdited: false };
    }

    handleChange = () => {
        const { primaryCare } = this.props;
        for (let i = 0; i < primaryCare.list.length; i++) {
            if (primaryCare.list[i].edited) {
                primaryCare.list[i].PanelizedEnrollees =
                    primaryCare.list[i].PreviousPanelizedEnrollees;
                primaryCare.list[i].PanelCapacity = primaryCare.list[i].PreviousPanelCapacity;
                primaryCare.list[i].RvuProductionCapacityNationalAverage =
                    primaryCare.list[i].PreviousRvuProductionCapacityNationalAverage;
                delete primaryCare.list[i].edited;
                delete primaryCare.list[i].PreviousPanelizedEnrollees;
                delete primaryCare.list[i].PreviousPanelCapacity;
                delete primaryCare.list[i]
                    .PreviPreviousRvuProductionCapacityNationalAverageousCapacity;
            }
        }
        this.setState({ fieldsEdited: false });
    };

    formSubmit = () => {
        const {
            primaryCare,
            currentFacility,
            currentHspc,
            marketName,
            dispatch,
            updatePrimaryCare
        } = this.props;
        const { fieldsEdited } = this.state;

        const data = _filter(primaryCare.list, { Facility: currentFacility, Hspc: currentHspc });
        for (let i = 0; i < data.length; i++) {
            if (data[i].edited) {
                delete primaryCare.list[i].edited;
                delete primaryCare.list[i].PreviousPanelizedEnrollees;
                delete primaryCare.list[i].PreviousPanelCapacity;
                delete primaryCare.list[i].PreviousRvuProductionCapacityNationalAverage;
            }
        }
        if (fieldsEdited) {
            updatePrimaryCare(data, marketName);
        } else {
            throw new SubmissionError({
                _error: 'Not saving, primary care values have not changed.'
            });
        }
        dispatch(reset('EditPrimaryCareModal'));

        this.setState({ fieldsEdited: false });
    };

    handleHide = () => {
        const { primaryCare, togglePrimaryCareModal, clearPrimaryCareModal, dispatch } = this.props;
        for (let i = 0; i < primaryCare.list.length; i++) {
            if (primaryCare.list[i].edited) {
                primaryCare.list[i].PanelizedEnrollees =
                    primaryCare.list[i].PreviousPanelizedEnrollees;
                primaryCare.list[i].PanelCapacity = primaryCare.list[i].PreviousPanelCapacity;
                primaryCare.list[i].RvuProductionCapacityNationalAverage =
                    primaryCare.list[i].PreviousRvuProductionCapacityNationalAverage;
                delete primaryCare.list[i].edited;
                delete primaryCare.list[i].PreviousPanelizedEnrollees;
                delete primaryCare.list[i].PreviousPanelCapacity;
                delete primaryCare.list[i].PreviousRvuProductionCapacityNationalAverage;
            }
        }
        dispatch(reset('UCHRCPrimaryCareModal'));
        togglePrimaryCareModal();
        clearPrimaryCareModal();
    };

    render() {
        const {
            primaryCare,
            currentFacility,
            currentHspc,
            defaultYear,
            modal,
            error,
            canEdit,
            pristine,
            submitting,
            reset: resetForm
        } = this.props;

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row) => {
                this.setState({ fieldsEdited: true });
                // TODO: Don't update parameter values
                row.RvuProductionCapacityNationalAverage = Math.round(
                    row.PanelCapacity * row.NationalAverageRvuPerPaneledMember
                );
            },
            beforeSaveCell: (oldValue, newValue, row, column) => {
                if (Number(oldValue) !== Number(newValue)) {
                    row.PreviousRvuProductionCapacityNationalAverage =
                        row.RvuProductionCapacityNationalAverage;
                    if (column.dataField === 'WorkFTEE' || column.id === 'WorkFTEE') {
                        row.PreviousPanelizedEnrollees = oldValue;
                        row.PanelizedEnrollees = Number(newValue);
                        row.edited = true;
                    }
                    if (column.dataField === 'PanelCapacity' || column.id === 'PanelCapacity') {
                        row.PreviousPanelCapacity = oldValue;
                        row.PanelCapacity = Number(newValue);
                        row.edited = true;
                    }
                }
            }
        };

        return (
            <div>
                <Modal size="lg" show={modal.show} onHide={this.handleHide}>
                    <Form
                        className="form-horizontal"
                        onSubmit={this.props.handleSubmit(this.formSubmit)}
                    >
                        <Modal.Header closeButton>
                            <div tabIndex={0} className="modal-title">
                                <h4>HSPCs Primary Care Capacity</h4>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <DropdownField
                                name="Facility"
                                label="Facility"
                                placeholder="Select facility..."
                                onChangeFunction={this.handleChange}
                                options={_uniqBy(primaryCare.list, 'Facility')}
                                displayField="Facility"
                                valueField="Facility"
                            />
                            <DropdownField
                                name="HSPC"
                                label="HSPC"
                                placeholder="Select HSPC..."
                                onChangeFunction={this.handleChange}
                                options={_uniqBy(
                                    _filter(
                                        primaryCare.list,
                                        (item) => item.Facility === currentFacility
                                    ),
                                    'Hspc'
                                )}
                                displayField="Hspc"
                                valueField="Hspc"
                            />
                            <ReactDataTable
                                keyValue="Id"
                                list={_filter(primaryCare.list, {
                                    Facility: currentFacility,
                                    Hspc: currentHspc
                                })}
                                columns={PrimaryCareCapacityModalColumnDefs}
                                loading={false}
                                cellEditOptions={cellEditProp}
                                isExportable={false}
                                isEditable
                            />
                            {error && <strong style={{ color: 'red' }}>{error}</strong>}
                        </Modal.Body>
                        <Modal.Footer style={{ height: '55px' }}>
                            <div className="col-sm-12">
                                <div className="form-group" style={{ paddingTop: '20px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            label={modal.saving ? 'Saving...' : 'Save'}
                                            type="submit"
                                            color="success"
                                            disabled={!canEdit || pristine || submitting}
                                        />
                                        &nbsp;&nbsp;
                                        <Button
                                            label="Cancel"
                                            color="warning"
                                            type="button"
                                            disabled={pristine || submitting}
                                            onClick={resetForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const selector = formValueSelector('UCHRCPrimaryCareModal');

const ReduxFormUCHRCPrimaryCareModal = reduxForm({
    form: 'UCHRCPrimaryCareModal',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.HSPC) {
            errors.HSPC = 'Required';
        }
        if (!values.Facility) {
            errors.Facility = 'Required';
        }
        return errors;
    }
})(UCHRCPrimaryCareModal);

const mapDispatchToProps = (dispatch) => {
    return {
        togglePrimaryCareModal: () => dispatch.InHouseCapacityModel.toggleModal('primaryCareModal'),
        updatePrimaryCare: (formValues, market) =>
            dispatch.InHouseCapacityModel.savePrimaryCareAsync({
                formValues,
                market
            }),
        clearPrimaryCareModal: () =>
            dispatch.InHouseCapacityModel.resetModalForm('primaryCareModal')
    };
};

const mapStateToProps = (state) => ({
    currentFacility: selector(state, 'Facility'),
    currentHspc: selector(state, 'HSPC'),
    initialValues: state.InHouseCapacityModel.primaryCareModal.form,
    modal: state.InHouseCapacityModel.primaryCareModal,
    marketName: getCurrentMarketName(state),
    primaryCare: getInHousePrimaryCareSelector(state),
    canEdit: canEditVISN(state),
    marketList: getMarketList(state),
    defaultYear: getDefaultYearObject(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUCHRCPrimaryCareModal);

import './style.css';

import React from 'react';

import Background from 'Content/images/va-banner.gif';

import StateGroupContainer from './StateGroupContainer';

const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'DC',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
];

const url =
    'https://dvagov.sharepoint.com/sites/VHAChiefStrategyOffice/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FVHAChiefStrategyOffice%2FShared%20Documents%2FState%20Policy%20Profiles';

export default class StatePolicyProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        return (
            <div style={{ display: 'grid', justifyContent: 'center' }}>
                <a className="stateBanner" href={url} target="_blank" rel="noopener noreferrer">
                    <img
                        width="1150px"
                        height="150px"
                        style={{ position: 'absolute' }}
                        src={Background}
                        alt="State Policy Profiles Reference Guide"
                    />
                    <div
                        style={{
                            color: 'white',
                            position: 'relative',
                            paddingLeft: '10px',
                            fontFamily: 'Calibri'
                        }}
                    >
                        <h1 style={{ fontSize: '28.0pt', marginBottom: 0, lineHeight: 1 }}>
                            2023-2024
                        </h1>
                        <h1 style={{ fontSize: '45.0pt', marginBottom: 0, lineHeight: 1 }}>
                            STATE POLICY PROFILES
                        </h1>
                        <h1 style={{ fontSize: '28.0pt', marginBottom: 0, lineHeight: 1 }}>
                            REFERENCE GUIDE
                        </h1>
                    </div>
                </a>
                <StateGroupContainer states={states} />
                <div style={{ height: '80px' }} />
            </div>
        );
    }
}

/**
 * Add provider modal.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import './style.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import UCManageProviders from '../../../UnderstandCapacity/Components/UCManageProviders';

class MCDProviderModal extends React.Component {
    render() {
        return (
            <div>
                <Modal
                    id="addProvidersModal"
                    size="lg"
                    show={this.props.show}
                    onHide={this.handleHide}
                >
                    <Modal.Header closeButton>
                        <div tabIndex={0} className="modal-title">
                            <h4>Add Supply Provider</h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <UCManageProviders />
                    </Modal.Body>
                    <div className="modal-footer"></div>
                </Modal>
            </div>
        );
    }
    handleHide = () => {
        this.props.toggleAddProviderModal();
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        toggleAddProviderModal: () => dispatch.PlannerModel.toggleProviderModal()
    };
};
const mapStateToProps = (state) => ({
    show: state.PlannerModel.AddProviderModal.show
});

export default connect(mapStateToProps, mapDispatchToProps)(MCDProviderModal);

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useHSPADispatch, useHSPASelector } from 'storeTypes';
import styled from 'styled-components/macro';

import LoadingSpinner from 'Lib/AsyncContent/LoadingPanel/LoadingSpinner';
import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { getVideoTutorialList } from 'Modules/UserSession/selector';
import { VideoTutorial } from 'Utilities/types';

const VideoContentPane = styled.div`
    padding-top: 15px;
`;

const BlankModalPanel = styled.div`
    height: 650px;
`;

type VideoTutorialResult = {
    list: VideoTutorial[];
    loading: boolean;
};

/**
 * Render and display the training video modal
 *
 * @returns Rendered modal
 */
const TrainingVideoPanelBody = (): JSX.Element => {
    const dispatch = useHSPADispatch();
    const { list, loading } = useHSPASelector(getVideoTutorialList) as VideoTutorialResult;

    const [activeTab, setActiveTab] = useState<string | undefined>();

    const handleOnClick = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchVideoTutorialsAsync = async () => {
            await dispatch.UserSessionModel.fetchVideoTutorialsAsync();
        };

        fetchVideoTutorialsAsync();
    }, []);

    useEffect(() => {
        if (list && list.length > 0 && !activeTab) {
            setActiveTab(list[0].key);
        }
    }, [list]);

    const videos = list.map(
        ({ key, label, src, captionSrc, transcriptSrc }: VideoTutorial): JSX.Element | null =>
            activeTab === key ? (
                <>
                    <video
                        id={key}
                        key={key}
                        width="100%"
                        height="600"
                        controls
                        preload="metadata"
                        crossOrigin="anonymous"
                    >
                        <source src={src} type="video/mp4" />
                        <track kind="captions" srcLang="en" src={captionSrc} />
                        Your browser does not support the video tag.
                    </video>
                    <div className="text-right">
                        <Button
                            href={transcriptSrc}
                            target="_blank"
                            rel="noreferrer"
                            variant="info"
                        >
                            {`Download ${label} Video Transcript`}
                        </Button>
                    </div>
                </>
            ) : null
    );

    if (loading) {
        return (
            <BlankModalPanel>
                <LoadingSpinner animation="border" variant="primary" />
            </BlankModalPanel>
        );
    }

    if (!list || list.length <= 0) {
        return (
            <BlankModalPanel>
                <span className="mx-auto align-middle text-center font-weight-bold">
                    Video Tutorials Have Not Been Loaded
                </span>
            </BlankModalPanel>
        );
    }

    return (
        <>
            <ModuleNavigation activeTab={activeTab} tabs={list} handleOnClick={handleOnClick} />
            <VideoContentPane>{videos}</VideoContentPane>
        </>
    );
};

export default TrainingVideoPanelBody;

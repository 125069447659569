/**
 * Values and Characteristics of the HSPA.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ESSPFValuesCharacteristics extends React.Component {
    render() {
        return (
            <div>
                <p>
                    VA/VHA Core Values, Characteristics, Goals, Objectives, and Priorities all serve
                    as a guide for strategic decision making and should be referenced throughout the
                    planning process. In addition, it is important to consider current influences,
                    such as the Choice Act, on VHA’s strategic directions.
                </p>
                <div style={{ textAlign: 'center' }}>
                    <h6>
                        <strong>Core Values</strong>
                    </h6>
                    <strong>
                        <span style={{ color: 'red' }}>I</span>
                    </strong>
                    ntegrity&nbsp;
                    <strong>
                        <span style={{ color: 'red' }}>C</span>
                    </strong>
                    ommitment&nbsp;
                    <strong>
                        <span style={{ color: 'red' }}>A</span>
                    </strong>
                    dvocacy&nbsp;
                    <strong>
                        <span style={{ color: 'red' }}>R</span>
                    </strong>
                    espect&nbsp;
                    <strong>
                        <span style={{ color: 'red' }}>E</span>
                    </strong>
                    xcellence
                    <br />
                    <br />
                    <h6>
                        <strong>Characteristics</strong>
                    </h6>
                    Trustworthy&nbsp; Accessible&nbsp; Quality&nbsp; Innovative&nbsp; Agile&nbsp;
                    Integrated
                    <br />
                    &nbsp;
                </div>
                <p>
                    The five Core Values underscore the obligations inherent in VA’s mission:
                    integrity, commitment, advocacy, respect, and excellence. The core values define
                    “who we are,” our culture and how we care for Veterans, Service members and
                    eligible beneficiaries. Institutionalizing these values is essential if we are
                    to achieve the long-term cultural change that assures Veterans they will always
                    be treated with dignity and respect. Our values are more than just words – they
                    affect outcomes in our daily interactions with Veterans and with each other.
                    Taking the first letter of each word – Integrity, Commitment, Advocacy, Respect,
                    Excellence – creates a powerful acronym, “I Care,” that reminds each VA employee
                    of the importance of their role in this Department. These core values come
                    together as five promises we make as individuals and as a department to those we
                    serve.
                </p>
                <p>
                    The Core Characteristics define “what we stand for,” and help guide how we will
                    perform our core mission. They shape our strategy, guide the execution of our
                    mission, and influence key decisions made within VA. The Characteristics are
                    Trustworthy, Accessible, Quality, Agile, Innovative, and Integrated.
                </p>
                <br />
                <Button
                    variant="primary"
                    onClick={() => this.props.getVaCoreValuesAndCharacteristicsPdf()}
                >
                    <FontAwesomeIcon icon={faFilePdf} />
                    &nbsp; Download PDF
                </Button>
                <div style={{ width: '100%', height: '30px' }}></div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getVaCoreValuesAndCharacteristicsPdf: () =>
            dispatch.UserSessionModel.fetchVaCoreValuesAndCharacteristicsPdfAsync()
    };
};
export default connect(null, mapDispatchToProps)(ESSPFValuesCharacteristics);

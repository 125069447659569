import React from 'react';
import Button from 'react-bootstrap/Button';

import { LONG_RANGE_PLAN } from 'Utilities/filePaths';

/**
 * Goals and objectives of the HSPA.
 *
 * @author Anthony P. Pancerella
 */
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const goalData = [
    {
        title: 'Goal 1',
        description:
            'VA consistently communicates with its customers and partners to assess and maximize performance, evaluate needs and build long-term relationships and trust',
        objectives: [
            {
                objectiveTitle: 'Objective 1.1',
                objectiveDescription:
                    "(Consistent and Easy to Understand Information) VA and partners use multiple channels and methods to ensure information about benefits, care and services is clear and easy to understand and access."
            },
            {
                objectiveTitle: 'Objective 1.2',
                objectiveDescription:
                    '(Lifelong Relationships and Trust) VA listens to Veterans, their families, caregivers, survivors, Service members, employees and other stakeholders to project future trends, anticipate needs and deliver effective and agile solutions that improve their outcomes, access and experiences.'
            }
        ]
    },
    {
        title: 'Goal 2',
        description:
            'VA delivers timely, accessible, high-quality benefits, care and services to meet the unique needs of Veterans and all eligible beneficiaries.',
        objectives: [
            {
                objectiveTitle: 'Objective 2.1',
                objectiveDescription:
                    '(Underserved, Marginalized and At-Risk Veterans) VA emphasizes the delivery of benefits, care and services to underserved, marginalized and at-risk Veterans to prevent suicide and homelessness, improve their economic security, health, resiliency and quality of life and achieve equity.'
            },
            {
                objectiveTitle: 'Objective 2.2',
                objectiveDescription:
                    '(Tailored Delivery of Benefits, Care and Services Ensure Equity and Access) VA and partners will tailor the delivery of benefits and customize whole health care and services for the recipient at each phase of their life journey.',
            },
            {
                objectiveTitle: 'Objective 2.3',
                objectiveDescription: '(Inclusion, Diversity, Equity, Accessibility (I-DEA)) VA will enhance understanding of Veteran needs and eliminate disparities and barriers to health, improve service delivery and opportunities to enhance Veterans’ outcomes, experiences and quality of life.',
            },
            {
                objectiveTitle: 'Objective 2.4',
                objectiveDescription: '(Innovative Care) VA will improve understanding of Veteran specific illnesses and injuries to develop and adopt innovative new treatments that prevent future illness and enhance Veteran outcomes.',
            },
            {
                objectiveTitle: 'Objective 2.5',
                objectiveDescription: '(Value and Sustainability) VA, with community partners, will deliver integrated care and services, balancing resources to ensure sustainability while continuing to deliver value and improve health and well-being outcomes of Veterans.',
            }
        ]
    },
    {
        title: 'Goal 3',
        description: 'VA builds and maintains trust with Stakeholders through proven stewardship, transparency and accountability.',
        objectives: [
            {
                objectiveTitle: 'Objective 3.1',
                objectiveDescription:
                    '(VA is Transparent and Trusted) VA will be the trusted agent for service and advocacy for our Nation’s heroes, caregivers, families, survivors and Service members to improve their quality of life and ensure end of life dignity.'
            },
            {
                objectiveTitle: 'Objective 3.2',
                objectiveDescription:
                    '(Internal and External Accountability) VA will continue to promote and improve organizational and individual accountability and ensure a just culture'
            }
        ]
    },
    {
        title: 'Goal 4',
        description:
            'VA ensures governance, systems, data and management best practices improve experiences, satisfaction, accountability and security.',
        objectives: [
            {
                objectiveTitle: 'Objective 4.1',
                objectiveDescription:
                    '(Our Employees Are Our Greatest Asset) VA will transform its human capital management capabilities to empower a collaborative culture that promotes information sharing, diversity, equity and inclusion and a competent, high-performing workforce to best serve Veterans and their families.'
            },
            {
                objectiveTitle: 'Objective 4.2',
                objectiveDescription:
                    '(Data is a Strategic Asset) VA will securely manage data as a strategic asset to improve VA’s understanding of customers and partners, drive evidence-based decision-making and deliver more effective and efficient solutions.'
            },
            {
                objectiveTitle: 'Objective 4.3',
                objectiveDescription:
                    '(Easy Access and Secure Systems) VA will deliver integrated, interoperable, secure and state-of-the-art systems to ensure convenient and secure access and improve the delivery of benefits, care and services.'
            },
            {
                objectiveTitle: 'Objective 4.4',
                objectiveDescription:
                    '(Evidence Based Decisions) VA will improve governance, management practices and make evidence-based decisions to ensure quality outcomes and experiences and efficient use of resources.'
            }
        ]
    }
];

/**
 * @param root0
 * @param root0.title
 * @param root0.description
 * @param root0.objectives
 */
const Goal = ({ title, description, objectives }) => {
    const subGoals = objectives ? (
        <ul>
            {objectives.map(({objectiveTitle, objectiveDescription}) => (
                <li key={objectiveTitle}>
                    <Goal title={objectiveTitle} description={objectiveDescription} />
                </li>
            ))}
        </ul>
    ) : null;

    return (
        <>
            <h6>
                <strong>{title}</strong>
            </h6>
            <p>{description}</p>
            {subGoals}
        </>
    );
};

/**
 *
 */
const GoalsObjectives = () => {
    const goals = goalData.map(({ title, description, objectives }) => (
        <Goal key={title} title={title} description={description} objectives={objectives} />
    ));

    return (
        <div>
            <div>
                <Button variant="link" href={LONG_RANGE_PLAN} target="_blank">
                    <span> FY22-25 Long Range Plan Framework </span>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Button>
            </div>
            {goals}
        </div>
    );
};

export default GoalsObjectives;

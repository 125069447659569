import React from 'react';
import Button from 'react-bootstrap/Button';

import table from 'Content/images/FY22-25 Long-Range Plan Framework.JPG';

/**
 * Priorities of the HSPA.
 * @author Anthony P. Pancerella
 */
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ESSPFVhaPriorities extends React.Component {
    render() {
        return (
            <div className="col-md-12">
                <div>
                    <Button
                        variant="link"
                        href="https://vaww.va.gov/VHAOPP/ospa/VHA_plan_framework_guide.asp"
                        target="_blank"
                    >
                        <span> CSO Planning Framework and Guidance </span>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Button>
                </div>
                <img
                    src={table}
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '80%'
                    }}
                    alt="FY22-25 Long-Range Plan Framework.  For more information, please visit the CSO Planning Framework and Guidance page in the previous link."
                />
            </div>
        );
    }
}

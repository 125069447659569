/**
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import './style.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import PatientOriginTable from '../../UDPatientOriginTool/UDPOTable';

class UDRPDPatientOriginModal extends React.Component {
    render() {
        return (
            <div>
                <Modal size="lg" show={this.props.modal.show} onHide={this.handleHide}>
                    <Modal.Header closeButton>
                        <div tabIndex={0} className="modal-title">
                            <h4>Patient Origins</h4>
                        </div>
                    </Modal.Header>
                    <div className="modal-body">
                        <PatientOriginTable
                            facility={this.props.modal.facility}
                            marketName={this.props.modal.market}
                            mpc={this.props.modal.mpc}
                            workloadType={this.props.modal.workloadType}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
    handleHide = () => {
        this.props.toggleFacilityModal(null, null, null, null);
        this.props.resetPOResult();
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetPOResult: () => dispatch.PatientOriginToolModel.resetTable('patientOrigins'),
        toggleFacilityModal: (facility, mpc, market, workloadType) =>
            dispatch.ReviewProjectedDemandModel.togglePOModal({
                facility: facility,
                mpc: mpc,
                market: market,
                workloadType: workloadType
            })
    };
};
const mapStateToProps = (state) => ({
    modal: state.ReviewProjectedDemandModel.facilityModal
});

export default connect(mapStateToProps, mapDispatchToProps)(UDRPDPatientOriginModal);

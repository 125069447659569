import React from 'react';

import styled from 'styled-components';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoGISPortal = styled.div`
    font-size: 1.75em;
    text-align: center;
`;

/**
 * ESDynamicMapping component renders a link to the VHA GIS Insight Planning Portal.
 *
 * @returns The rendered component.
 */
const ESDynamicMapping = () => {
    const marketInHSPAViewerURL = process.env.REACT_APP_HSPA_VIEWER_URL;

    return (marketInHSPAViewerURL ? (
        <div>
            <p>
                {' '}
                Using the VHA GIS Insight Planning Portal tools, review the destiny of enrolled
                Veteran population in your area, noting their proximity to the nearest VA
                are useful when presenting potential strategic solutions to other local other local
                other local leadership and stakeholders.
            </p>
            <a
                id="mapAnchorTag"
                href={marketInHSPAViewerURL}
                style={{ color: '#0000FF', textAlign: 'left' }}
                target="_blank"
                rel="noopener noreferrer"
            >
                VHA GIS Insight Planning Portal{' '}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
        </div>) : (
        <NoGISPortal>
            The VHA GIS Insight Planning Portal is only available within the VA Network.
        </NoGISPortal>
    ));
};

export default ESDynamicMapping;

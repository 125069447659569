import React from 'react';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { canEditVISN, getMarketList, getVISNList } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { Form, formValueSelector, getFormValues, reduxForm } from 'redux-form';

import FormButtonGroup from '../RWFButtonGroup';

/**
 * RWFHeader component handles the header section of the report wizard form.
 */
class RWFHeader extends React.Component {
    static propTypes = {
        formValues: PropTypes.shape({
            VisnCode: PropTypes.string,
            ReportId: PropTypes.number
        }).isRequired,
        change: PropTypes.func.isRequired,
        untouch: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        currentVISN: PropTypes.string.isRequired,
        marketList: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired,
        visnList: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired,
        report: PropTypes.shape({
            ReportForm: PropTypes.shape({
                loading: PropTypes.bool
            })
        }).isRequired,
        gapMPCTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        previousPage: PropTypes.oneOf([PropTypes.func, PropTypes.number]).isRequired,
        currentPage: PropTypes.oneOf([PropTypes.func, PropTypes.number]).isRequired,
        getReportDropdowns: PropTypes.func.isRequired,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false,
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true
    };

    onVISNAndMarketSelect = (option, inputName) => {
        const { formValues, getReportDropdowns, change, untouch } = this.props;

        if (inputName === 'MarketName') {
            getReportDropdowns(option, formValues.VisnCode);
        }
        if (inputName === 'VISN') {
            change('MarketName', '');
            untouch('ReportForm', 'MarketName');
        }
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    /**
     * Renders the textbox field for the report title.
     *
     * @param report - The report object.
     * @returns The textbox field component.
     */
    static renderTextboxField(report) {
        return (
            <TextboxField
                name="ReportName"
                label="Report Title"
                labelPosition={12}
                inputPosition={12}
                loading={report.ReportForm.loading}
            />
        );
    }

    /**
     * Renders the dropdown fields for VISN and Market.
     *
     * @param currentVISN - The current VISN code.
     * @param marketList - The list of markets.
     * @param visnList - The list of VISNs.
     * @returns The dropdown fields.
     */
    renderDropdownFields(currentVISN, marketList, visnList) {
        return (
            <>
                <DropdownField
                    name="VisnCode"
                    label="VISN"
                    options={visnList.list}
                    onChangeFunction={this.onVISNAndMarketSelect}
                    displayField="DisplayName"
                    valueField="VisnCode"
                    labelPosition={12}
                    inputPosition={12}
                />
                <DropdownField
                    name="MarketName"
                    label="Market"
                    options={_filter(marketList.list, (item) => currentVISN === item.VisnCode)}
                    onChangeFunction={this.onVisnAndMarketSelect}
                    displayField="MarketName"
                    valueField="MarketName"
                    labelPosition={12}
                    inputPosition={12}
                />
            </>
        );
    }

    /**
     * Renders the MultiSelectField component.
     *
     * @param gapMPCTypes - The gap MPC types.
     * @returns The MultiSelectField component.
     */
    static renderMultiSelectField(gapMPCTypes) {
        return (
            <MultiSelectField
                name="Hspc"
                label="HSPC"
                options={gapMPCTypes.list}
                valueField="Hspc"
                delimiter={'{;}'}
                placeholder="To customize a report based on a specific Health Strategic Planning Category (HSPC), please select the HSPC(s) you would like to view."
                labelPosition={12}
                inputPosition={12}
                loading={gapMPCTypes.loading}
            />
        );
    }

    /**
     * Renders the form button group.
     *
     * @param previousPage - The previous page function or number.
     * @param submitting - The submitting state.
     * @param saving - The saving state.
     * @param generating - The generating state.
     * @param loading - The loading state.
     * @param invalid - The invalid state.
     * @param pristine - The pristine state.
     * @param reset - The reset function.
     * @param currentPage - The current page function or number.
     * @param canEdit - The canEdit flag.
     * @returns The form button group component.
     */
    renderFormButtonGroup(
        previousPage,
        submitting,
        saving,
        generating,
        loading,
        invalid,
        pristine,
        reset,
        currentPage,
        canEdit
    ) {
        return (
            <div>
                <div className="col-xs-12" style={{ paddingTop: 15 }}>
                    <FormButtonGroup
                        formGenerate={this.formGenerate}
                        previousPage={previousPage}
                        submitting={submitting}
                        saving={saving}
                        generating={generating}
                        loading={loading}
                        invalid={invalid}
                        pristine={pristine}
                        reset={reset}
                        isFirstPage={currentPage === 0}
                        isLastPage={currentPage === 4}
                        canEdit={canEdit}
                    />
                </div>
            </div>
        );
    }

    /**
     * Renders the form components.
     *
     * @returns The form components.
     */
    render() {
        const {
            currentVISN,
            marketList,
            visnList,
            report,
            gapMPCTypes,
            previousPage,
            submitting,
            saving,
            generating,
            loading,
            invalid,
            pristine,
            reset,
            currentPage,
            canEdit,
            handleSubmit
        } = this.props;

        return (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
                {RWFHeader.renderTextboxField(report)}
                {this.renderDropdownFields(currentVISN, marketList, visnList)}
                {RWFHeader.renderMultiSelectField(gapMPCTypes)}
                {this.renderFormButtonGroup(
                    previousPage,
                    submitting,
                    saving,
                    generating,
                    loading,
                    invalid,
                    pristine,
                    reset,
                    currentPage,
                    canEdit
                )}
            </Form>
        );
    }
}

const ReduxRWFHeader = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFHeader);

const selector = formValueSelector('ReportForm');

/**
 * Maps dispatch to props for the component.
 *
 * @param dispatch - The dispatch function.
 * @returns The mapped dispatch functions.
 */
const mapDispatchToProps = (dispatch) => ({
    /**
     * Fetches dropdown options based on market name and VISN code.
     *
     * @param marketName - The name of the market.
     * @param visnCode - The VISN code.
     * @returns The dispatch function.
     */
    getReportDropdowns: (marketName, visnCode) =>
        dispatch.ReportFormModel.fetchDropdownOptionsAsync({
            marketName,
            visnCode
        }),
    /**
     * Clears the report form.
     *
     * @returns The dispatch function.
     */
    clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),

    /**
     * Generates a report by ID.
     *
     * @param reportId - The ID of the report to generate.
     * @returns The dispatch function.
     */
    generateReportById: dispatch.ReportFormModel.generateReportByIdAsync,
    generateReportByFormData: dispatch.ReportFormModel.generateReportByFormDataAsync
});

/**
 * Maps state to props for the component.
 *
 * @param state - The current state.
 * @returns The mapped state properties.
 */
const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    currentVISN: selector(state, 'VisnCode'),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    generating: state.ReportFormModel.ReportForm.generating,
    loading: state.ReportFormModel.ReportForm.loading,
    gapMPCTypes: state.UserSessionModel.gapMpcTypes,
    report: state.ReportFormModel,
    visnList: getVISNList(state),
    marketList: getMarketList(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxRWFHeader);

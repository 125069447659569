import './style.css';

/**
 * Site Action -> Manage Users -> add or edit user tab.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import _isEmpty from 'lodash/isEmpty';

import Button from 'Lib/Button';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import TextAreaField from 'Lib/ReduxFormFields/TextAreaField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { getGapMpcTypesSelector } from 'Modules/UnderstandDemand/Services/selector';
import { canEditVISN, getCurrentMarketName, getCurrentVISN } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { Form, reduxForm, reset } from 'redux-form';

class FacilityForm extends React.Component {
    static propTypes = {
        initialValues: PropTypes.shape({}).isRequired,
        gapMPCTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired,
        facilityModal: PropTypes.shape({
            show: PropTypes.bool,
            statusDrp: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            }),
            statesDrp: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            }),
            facilityForm: PropTypes.shape({
                saving: PropTypes.bool
            }),
            saving: PropTypes.bool
        }).isRequired,
        marketName: PropTypes.string.isRequired,
        canEdit: PropTypes.bool,
        fetchGapMPCTypesAsync: PropTypes.func.isRequired,
        fetchStatusDropdownOptionsAsync: PropTypes.func.isRequired,
        fetchStateDropdownOptionsAsync: PropTypes.func.isRequired,
        saveVaFacilityAsync: PropTypes.func.isRequired,
        clearFacilityForm: PropTypes.func.isRequired,
        toggleFacilityModal: PropTypes.func.isRequired,
        pristine: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false,
        pristine: true
    };

    componentWillMount() {
        const {
            gapMPCTypes,
            facilityModal,
            fetchGapMPCTypesAsync,
            fetchStatusDropdownOptionsAsync,
            fetchStateDropdownOptionsAsync
        } = this.props;

        if (gapMPCTypes.list.length === 0) {
            fetchGapMPCTypesAsync();
        }
        if (facilityModal.statusDrp.list.length === 0) {
            fetchStatusDropdownOptionsAsync();
        }
        if (facilityModal.statesDrp.list.length === 0) {
            fetchStateDropdownOptionsAsync();
        }
    }

    componentWillUnmount() {
        const { clearFacilityForm, dispatch } = this.props;

        clearFacilityForm();
        dispatch(reset('FacilityForm'));
    }

    formSubmit = (values) => {
        const { marketName, saveVaFacilityAsync, clearFacilityForm, dispatch } = this.props;

        // TODO: Don't assign to parameter values
        values.Market = marketName;
        saveVaFacilityAsync(values);
        clearFacilityForm();
        dispatch(reset('FacilityForm'));
    };

    handleHide = () => {
        const { toggleFacilityModal, clearFacilityForm, dispatch } = this.props;

        toggleFacilityModal();
        clearFacilityForm();
        dispatch(reset('FacilityForm'));
    };

    render() {
        const {
            initialValues,
            marketName,
            gapMPCTypes,
            facilityModal,
            canEdit,
            pristine,
            reset: resetForm,
            handleSubmit
        } = this.props;

        const formType = _isEmpty(initialValues) ? 'create' : 'edit';

        return (
            <div>
                <Modal size="lg" show={facilityModal.show} onHide={this.handleHide}>
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <Modal.Header closeButton>
                            <div tabIndex={0} className="modal-title">
                                <h4>
                                    {formType === 'create' ? 'Create Facility' : 'Edit Facility'}
                                </h4>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <TextboxField
                                name="Market"
                                label="Market"
                                disabled
                                defaultValue={marketName}
                            />
                            <DropdownField
                                name="State"
                                label="State"
                                options={facilityModal.statesDrp.list}
                                loading={facilityModal.statesDrp.loading}
                                disabled={facilityModal.facilityForm.saving}
                                displayField="DisplayField"
                                valueField="ValueField"
                            />
                            <TextboxField
                                name="City"
                                label="City"
                                disabled={facilityModal.facilityForm.saving}
                            />
                            <TextboxField
                                name="Facility"
                                label="Facility"
                                disabled={facilityModal.facilityForm.saving}
                            />
                            <TextboxField
                                name="StationNumber"
                                label="Station Number"
                                disabled={facilityModal.facilityForm.saving}
                            />
                            <MultiSelectField
                                name="SPCListString"
                                label="Select HSPC"
                                options={gapMPCTypes.list}
                                valueField="Hspc"
                                disabled={facilityModal.facilityForm.saving}
                            />
                            <DropdownField
                                name="Status"
                                label="Status"
                                options={facilityModal.statusDrp.list}
                                loading={facilityModal.statusDrp.loading}
                                disabled={facilityModal.facilityForm.saving}
                                displayField="DisplayField"
                                valueField="ValueField"
                            />
                            <TextAreaField
                                name="Description"
                                label="Explanation"
                                labelPosition={2}
                                inputPosition={10}
                                disabled={facilityModal.facilityForm.saving}
                            />
                            <TextboxField
                                name="AnticipatedDate"
                                label="Anticipated Activation Year"
                                disabled={facilityModal.saving === true}
                            />
                        </Modal.Body>
                        <Modal.Footer style={{ height: '55px' }}>
                            <div className="col-sm-12">
                                <div
                                    className="form-group"
                                    style={{ paddingTop: '20px', textAlign: 'center' }}
                                >
                                    <Button
                                        color="success"
                                        type="submit"
                                        label={
                                            formType === 'create'
                                                ? 'Save New Facility'
                                                : 'Update Facility'
                                        }
                                        disabled={!canEdit}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        color="warning"
                                        type="button"
                                        disabled={pristine || facilityModal.saving}
                                        onClick={resetForm}
                                        label="Cancel"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const ReduxFormFacilityForm = reduxForm({
    form: 'FacilityForm',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.State) {
            errors.State = 'Required';
        }
        if (!values.City) {
            errors.City = 'Required';
        }
        if (!values.Facility) {
            errors.Facility = 'Required';
        }
        if (!values.StationNumber) {
            errors.StationNumber = 'Required';
        }
        if (!values.SPCListString) {
            errors.SPCListString = 'Required';
        }
        if (!values.Status) {
            errors.Status = 'Required';
        }
        if (!/[0-1]{1}[0-9]\/[\d]{4}/.test(values.AnticipatedDate)) {
            errors.AnticipatedDate = 'Enter a valid date. E.g.:"mm/yyyy"';
        }
        return errors;
    }
})(FacilityForm);

const mapDispatchToProps = (dispatch) => {
    return {
        toggleFacilityModal: dispatch.VaFacilitiesModel.toggleFacilityModal,
        saveVaFacilityAsync: dispatch.VaFacilitiesModel.saveVaFacilityAsync,
        clearFacilityForm: dispatch.VaFacilitiesModel.clearForm,
        fetchGapMPCTypesAsync: dispatch.UserSessionModel.fetchGapMpcTypesAsync,
        fetchStateDropdownOptionsAsync: dispatch.VaFacilitiesModel.fetchStateDropdownOptionsAsync,
        fetchStatusDropdownOptionsAsync: dispatch.VaFacilitiesModel.fetchStatusDropdownOptionsAsync
    };
};

const mapStateToProps = (state) => ({
    gapMPCTypes: getGapMpcTypesSelector(state),
    facilityModal: state.VaFacilitiesModel.facilityModal,
    initialValues: state.VaFacilitiesModel.facilityModal.facilityForm.formData,
    marketName: getCurrentMarketName(state),
    currentVisn: getCurrentVISN(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormFacilityForm);

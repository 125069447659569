import './style.css';

import React from 'react';
import FontAwesome from 'react-fontawesome';
import Select from 'react-select';

import _map from 'lodash/map';

import PropTypes from 'prop-types';

import { Field } from 'redux-form';

/**
 * DropdownField component renders a dropdown field with validation and loading state.
 */
export default class DropdownField extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        loading: PropTypes.bool.isRequired,
        displayField: PropTypes.string.isRequired,
        valueField: PropTypes.string.isRequired,
        labelPosition: PropTypes.number,
        inputPosition: PropTypes.number,
        disabled: PropTypes.bool,
        onChangeFunction: PropTypes.func,
        value: PropTypes.string
    };

    static defaultProps = {
        placeholder: '',
        disabled: false,
        labelPosition: 2,
        inputPosition: 10,
        value: '',
        onChangeFunction: null
    };

    /**
     * Renders the dropdown field component.
     *
     * @returns The rendered dropdown field component.
     */
    render() {
        const {
            name,
            label,
            placeholder,
            options,
            loading,
            displayField,
            value,
            valueField,
            disabled,
            labelPosition,
            inputPosition,
            onChangeFunction
        } = this.props;

        /**
         * Handles the change event for the dropdown field.
         *
         * @param props - The properties passed to the component.
         * @param param1 - The value object.
         * @param param1.value - The selected value.
         */
        function handleChange(props, { value }) {
            if (props.onChangeFunction) {
                props.onChangeFunction(value, props.input.name);
            }
            props.input.onChange(value);
        }

        /**
         * Renders the dropdown field with validation and loading state.
         *
         * @param props - The properties passed to the component.
         * @returns The rendered dropdown field.
         */
        function renderDropDownField(props) {
            let validationState = '';
            if (props.meta.touched) {
                if (props.meta.error) {
                    validationState = 'invalidDropdown';
                } else {
                    validationState = 'validDropdown';
                }
            }
            const options = _map(props.options, (item) => ({
                label: item[props.displayField],
                value: item[props.valueField],
                data: item
            }));

            const hasError = props.meta.touched && props.meta.error;
            const errorId = `${props.input.name}-error`;

            return (
                <div className="form-group row">
                    <div className={`col-sm-${props.labelPosition} col-form-label`}>
                        <strong>{`${props.label}:`}</strong>
                    </div>
                    <div className={`col-sm-${props.inputPosition}`}>
                        <Select
                            {...props.input}
                            className={validationState}
                            closeOnSelect
                            disabled={props.disabled}
                            options={options}
                            placeholder={props.placeholder}
                            onChange={(value) => handleChange(props, value)}
                            value={options.filter(({ value }) => `${value}` === `${props.input.value}`)}
                            aria-label={props.label}
                            aria-invalid={hasError}
                            aria-errormessage={errorId}
                        />
                        {props.loading === true ? (
                            <div className="feedback-icon">
                                <FontAwesome name="check" spin className="fa fa-spinner" />
                            </div>
                        ) : null}
                        <span id={errorId} className="invalid-feedback d-block">
                            {hasError ? props.meta.error : null}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <Field
                name={name}
                label={label}
                aria-label={label}
                placeholder={placeholder === '' ? `Enter ${label.toLowerCase()}...` : placeholder}
                options={options}
                loading={loading}
                displayField={displayField}
                value={value}
                valueField={valueField}
                disabled={disabled}
                labelPosition={labelPosition}
                inputPosition={inputPosition}
                onChangeFunction={onChangeFunction}
                component={renderDropDownField}
            />
        );
    }
}

import React from 'react';
import { Button } from 'react-bootstrap';

import {
    faObjectGroup,
    faObjectUngroup,
    faSort,
    faSortDown,
    faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender, Header, Table } from '@tanstack/react-table';

import FilterHeader from './FilterHeader';

export type HeaderProps = {
    table: Table<unknown>;
};

/**
 * The table header component.
 *
 * @param props - The HeaderProps passed into the component.
 * @param props.table - The table.
 * @returns - The component.
 */
const TableHeader = ({ table }: HeaderProps) => {
    /**
     * Renders the column head for the Capital Project table.
     *
     * @param header - The header to render.
     * @returns - The rendered header.
     */
    const renderColumnHeader = (header: Header<unknown, unknown>): JSX.Element => {
        if (header.isPlaceholder) {
            return <></>;
        }

        let sortIcon = faSort;
        let sortTitle = `Column ${
            header.column.columnDef.meta?.headerDisplayName || ''
        } is Unsorted`;
        if (header.column.getIsSorted() === 'asc') {
            sortIcon = faSortUp;
            sortTitle = `Column ${
                header.column.columnDef.meta?.headerDisplayName || ''
            } Sorted in Ascending Order`;
        } else if (header.column.getIsSorted() === 'desc') {
            sortIcon = faSortDown;
            sortTitle = `Column ${
                header.column.columnDef.meta?.headerDisplayName || ''
            } Sorted in Descending Order`;
        }

        const groupingSection =
            header.column.columnDef.enableGrouping === true ? (
                <Button
                    size="sm"
                    variant="light"
                    title={
                        header.column.getIsGrouped() === true
                            ? `Ungroup Table by ${
                                  header.column.columnDef.meta?.headerDisplayName || 'this column'
                              }`
                            : `Group Table by ${
                                  header.column.columnDef.meta?.headerDisplayName || 'this column'
                              }`
                    }
                    onClick={header.column.getToggleGroupingHandler()}
                >
                    <FontAwesomeIcon
                        icon={
                            header.column.getIsGrouped() === true ? faObjectUngroup : faObjectGroup
                        }
                    />
                </Button>
            ) : null;

        return (
            <>
                {groupingSection}
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.columnDef.enableSorting === true ? (
                    <Button
                        size="sm"
                        variant="light"
                        title={sortTitle}
                        onClick={header.column.getToggleSortingHandler()}
                    >
                        <FontAwesomeIcon icon={sortIcon} />
                    </Button>
                ) : null}
                {header.column.columnDef.enableColumnFilter === true ? (
                    <FilterHeader column={header.column} />
                ) : null}
            </>
        );
    };
    return (
        <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <th
                            key={header.id}
                            scope="col"
                            tabIndex={0}
                            colSpan={header.colSpan}
                            aria-label={
                                header.column.columnDef.meta
                                    ? header.column.columnDef.meta.headerDisplayName
                                    : header.column.id
                            }
                            className={
                                header.column.columnDef.meta
                                    ? header.column.columnDef.meta.columnClassName
                                    : ''
                            }
                            style={
                                header.column.columnDef.meta
                                    ? header.column.columnDef.meta.columnStyle
                                    : {}
                            }
                        >
                            {renderColumnHeader(header)}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default TableHeader;

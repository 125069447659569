import React, { useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import SAApplicationAnalytics from './SAApplicationAnalytics';
import SAViewQuestions from './SAViewQuestions';

const tabConfig = {
    'user-questions': SAViewQuestions,
    analytics: SAApplicationAnalytics
};

const AdministrationModal = () => {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.AdministrationModel.show);

    const [tab, setTab] = useState('user-questions');

    const ContentComponent = tabConfig[tab];
    const content = <ContentComponent />;

    return (
        <Modal size="xl" show={show} onHide={dispatch.AdministrationModel.toggle}>
            <Modal.Header closeButton>
                <Modal.Title tabIndex={0}>HSPA Administration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Nav
                    fill
                    justify
                    variant="pills"
                    defaultActiveKey="user-questions"
                    navbar={false}
                    className="mb-2"
                >
                    <Nav.Item onClick={() => setTab('user-questions')}>
                        <Nav.Link eventKey="user-questions">User Questions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTab('analytics')}>
                        <Nav.Link eventKey="analytics">Analytics</Nav.Link>
                    </Nav.Item>
                </Nav>
                {content}
            </Modal.Body>
        </Modal>
    );
};

export default AdministrationModal;

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { deleteCapitalProject } from 'Modules/CapitalProject';
import { CapitalProject } from 'Utilities/types';

type Props = {
    capitalProject: CapitalProject;
};

const DeleteCapitalProjectModal = ({ capitalProject }: Props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const deleteProject = () => {
        dispatch(deleteCapitalProject(capitalProject.id as number));
        handleModalClose();
    };

    return (
        <>
            <Button
                variant="danger"
                onClick={handleModalOpen}
                style={{ float: 'right', borderRadius: '16px' }}
            >
                Delete
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title tabIndex={0}>Delete {capitalProject.projectNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete capital project {capitalProject.projectNumber}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteProject}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleModalClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteCapitalProjectModal;

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import ShortButton from 'Lib/ShortButton';
import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { MarketDDFContent } from 'Modules/SlideData/DDFSlides';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DDFPane from '../DDFPane';

interface reportSection {
    sectionHeader: string;
    sectionSlides: MarketDDFContent[][];
}

interface modalProps {
    title?: string;
    reportSections: reportSection[];
    iconSize?: SizeProp;
}

const defaultProps: modalProps = {
    title: undefined,
    reportSections: [],
    iconSize: '2x'
};

function DDFModal({ title, reportSections, iconSize }: modalProps) {
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleClose = () => {
        setShow(false);
        setActiveTab(0);
    };
    const handleShow = () => setShow(true);
    const changeTab = (tab: React.SetStateAction<number>) => {
        setActiveTab(tab);
    };

    const tabs = reportSections.map(({ sectionHeader }, index) => ({
        key: index,
        label: sectionHeader
    }));

    const reportPanes = reportSections.map((section) => (
        <DDFPane sectionSlides={section.sectionSlides} />
    ));

    const navigation =
        reportPanes.length > 1 ? (
            <ModuleNavigation activeTab={activeTab} tabs={tabs} handleOnClick={changeTab} />
        ) : null;

    const hide = reportSections?.length === 0;

    return (
        <>
            <ShortButton
                variant="warning"
                onClick={handleShow}
                style={{ marginRight: '5px', marginLeft: '5px' }}
                hidden={hide}
            >
                <FontAwesomeIcon
                    icon={faBookOpen}
                    size={iconSize}
                    style={{ verticalAlign: 'middle' }}
                />
                <span> {title}</span>
            </ShortButton>

            <Modal show={show} onHide={handleClose} size="lg" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title tabIndex={0}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {navigation}
                    <div style={{ paddingTop: '5px' }}>{reportPanes[activeTab]}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

DDFModal.defaultProps = defaultProps;

export default DDFModal;

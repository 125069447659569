export enum OpportunityStage {
    ANALYSIS = 'Analysis',
    PLANNING = 'Planning',
    ACTIVATION = 'Activation',
    VERIFICATION = 'Verification',
    COMPLETE = 'Complete'
}

export const OPPORTUNITY_STAGES = [
    OpportunityStage.ANALYSIS,
    OpportunityStage.PLANNING,
    OpportunityStage.ACTIVATION,
    OpportunityStage.VERIFICATION,
    OpportunityStage.COMPLETE
];

export enum FacilityStatus {
    OPENING,
    CLOSURE
}

export type FacilityUpdate = {
    name: string;
    updateStatus?: FacilityStatus;
    servicesAdded: string[];
    servicesRemoved: string[];
};

export type Opportunity = {
    opportunityDescription: string;
    isFieldOpportunity: boolean;
    servicesAffected: FacilityUpdate[];
    status?: OpportunityStage;
    isEnabled?: boolean;
};

export type Recommendation = {
    created: Date | string;
    id: number;
    facilityLevel: string;
    facilityLevelId: number | null;
    marketLevel: string;
    marketLevelId: number;
    modified: Date | string;
    recJustification: string;
    recLanguage: string;
    recType: string;
    recTypeId: number;
    sectionNumbers: string;
    visnLevel: string;
    visnLevelId: number;
    serviceLines: string[];
};

export type ApprovalQuestion = {
    id: number;
    question: string;
    answer: boolean;
};

export type ApprovalQuestionDTO = {
    ID: number;
    Question: string;
    Answer: boolean;
};

export type CapitalProject = {
    id?: number;
    visnNumber: number;
    facilityId: string;
    facility: string;
    marketID: number;
    market?: string;
    capitalProjectTypeID: number;
    capitalProjectType?: string;
    capitalProjectStatusID?: number;
    capitalProjectStatus?: string;
    projectNumber?: string;
    description: string | undefined;
    approvalJustification?: string;
    year: number | undefined;
    completionYear: number | undefined;
    isDisposalProject: boolean;
    isSCIPProject: boolean;
    acuteInpatientMedicineSurgery?: number;
    inpatientMentalHealth?: number;
    acuteSpecialPrograms?: number;
    clcHospice?: number;
    ancillaryDiagnostic?: number;
    primaryCare?: number;
    specialtyCare?: number;
    mentalHealth?: number;
    administration?: number;
    support?: number;
    commonSwingConstruction?: number;
    research?: number;
    plans?: NameId[];
    specialties?: NameId[];
    approvalQuestions?: ApprovalQuestion[];
};

export type CapitalProjectDTO = {
    ID?: number;
    VisnNumber: number;
    FacilityId: string;
    Facility: string;
    MarketID: number;
    Market: string;
    CapitalProjectTypeID: number;
    CapitalProjectType?: string;
    CapitalProjectStatusID?: number;
    CapitalProjectStatus?: string;
    ProjectNumber?: string;
    Description: string | undefined;
    ApprovalJustification?: string;
    Year: number;
    CompletionYear: number;
    IsDisposalProject: boolean;
    IsSCIPProject: boolean;
    AcuteInpatientMedicineSurgery?: number;
    InpatientMentalHealth?: number;
    AcuteSpecialPrograms?: number;
    CLCHospice?: number;
    AncillaryDiagnostic?: number;
    PrimaryCare?: number;
    SpecialtyCare?: number;
    MentalHealth?: number;
    Administration?: number;
    Support?: number;
    CommonSwingConstruction?: number;
    Research?: number;
    Plans?: NameIdDTO[];
    Specialties?: NameIdDTO[];
    ApprovalQuestions?: ApprovalQuestionDTO[];
};

export type FacilitySelectionDTO = {
    facility: string;
    facilityId: string;
};

/**
 * Gets the total square footage of a CapitalProject.
 *
 * @param capitalProject - The capital project to calculate the square footage from.
 * @returns - The total square footage of the project.
 */
export const getTotalSquareFootage = (capitalProject: CapitalProject): number =>
    (capitalProject.acuteInpatientMedicineSurgery || 0) +
    (capitalProject.inpatientMentalHealth || 0) +
    (capitalProject.acuteSpecialPrograms || 0) +
    (capitalProject.clcHospice || 0) +
    (capitalProject.ancillaryDiagnostic || 0) +
    (capitalProject.primaryCare || 0) +
    (capitalProject.specialtyCare || 0) +
    (capitalProject.mentalHealth || 0) +
    (capitalProject.administration || 0) +
    (capitalProject.support || 0) +
    (capitalProject.commonSwingConstruction || 0) +
    (capitalProject.research || 0);

/**
 * Function for shortening a description without removing words.
 *
 * @param description - The description to shorten.
 * @param maxLength - The maximum number of characters to use.
 * @param separator - The separator to use (defaults to a empty space).
 * @returns - The shortened description.
 */
export function shortenDescription(
    description: string,
    maxLength: number,
    separator = ' '
): string {
    if (!description || description.length <= maxLength) {
        return description;
    }

    return `${description.substring(0, description.lastIndexOf(separator, maxLength))}...`;
}

type BaseModel = {
    Id: number;
};

type BaseModelYear = BaseModel & {
    Year: number;
};

export type GapMpcType = BaseModelYear & {
    MpcType: string;
    HspcGroup: string;
    Hspc?: string;
    WorkloadUnit?: string;
    MpcStr: string;
    HspcGroupWorkloadString: string;
};

export type CapitalProjectPlan = {
    PlanID: number;
    CapitalProjectID: number;
    CapitalProject: CapitalProjectDTO;
};

export type PlanSelected = {
    PlanID: number;
    Year: number;
    MarketID: number;
    SpecialtyID: number;
    Justification: string;
    Specialty: GapMpcType;
};

export type Plan = BaseModelYear & {
    PlanName?: string;
    CurrentHspcWorkloadType: number;
    MarketName: string;
    HspcId: number;
    Description?: string;
    SCPString: string;
    WorkloadUnit: string;
    CurrentHspcWorkloadTypeString: string;
    Hspc: GapMpcType;
    IsSelected: boolean;
    CapitalProjectPlans: CapitalProjectPlan[];
    PlanSelected: PlanSelected[];
};

export type NameId = {
    id: string;
    name: string;
};

export type NameIdDTO = {
    Id: string;
    Name: string;
};

export type Market = BaseModelYear & {
    MarketNameDropDown: string;
    MarketId: string;
    MarketName: string;
    VisnCode: string;
    VisnId: number;
};

export type Visn = BaseModelYear & {
    VisnNumber: number;
    DisplayName: string;
    VisnCode: string;
};

type YearsBase = {
    YearM1: number;
    Year0: number;
    Year1: number;
    Year2: number;
    Year3: number;
    Year4: number;
    Year5: number;
    Year6: number;
    Year7: number;
    Year8: number;
    Year9: number;
    Year10: number;
};

type YearsOrgBase = {
    YearM1_org: number;
    Year0_org: number;
    Year1_org: number;
    Year2_org: number;
    Year3_org: number;
    Year4_org: number;
    Year5_org: number;
    Year6_org: number;
    Year7_org: number;
    Year8_org: number;
    Year9_org: number;
    Year10_org: number;
};

export type FacilityType = BaseModel & {
    Name: string;
    IsVAFacility: number;
};

export type FqhcFacility = BaseModel & {
    Name: string;
    GranteeName?: string;
    State?: string;
    City?: string;
    Address?: string;
    ZipCode?: string;
    County?: string;
    Description_1?: string;
    Description_2?: string;
    CongressionalDistrict?: string;
    FacilityTypeId: number;
    FacilityType?: FacilityType;
};

export type IHS = BaseModelYear & {
    ASUFAC?: string;
    FacilityName?: string;
    ServiceUnitName?: string;
    AreaName?: string;
    Status?: string;
    FacilityType?: string;
    Address?: string;
    City?: string;
    State?: string;
    ZIP?: string;
    Telephone?: string;
};

export type AcademicAffiliation = BaseModel & {
    StnNumber?: string;
    VaFacility?: string;
    Affiliate?: string;
    AddressString?: string;
    IsSystem?: boolean;
    Address1?: string;
    Address2?: string;
    Zip?: string;
    City?: string;
    State?: string;
};

export type DoDInstallation = BaseModel & {
    Label?: string;
    State?: string;
    FacilityCityName?: string;
    Facility5DigitZIPCode?: string;
    FacilityName?: string;
};

export type VASpecialty = BaseModel & {
    Specialty?: string;
    SPC?: string;
};

export type CommunityProviderVASpecialty = BaseModel & {
    CommunityProviderId?: number;
    CommunityProvider?: CommunityProvider;
    VASpecialtyId?: number;
    VASpecialty?: VASpecialty;
};

export type CommunityProvider = BaseModel & {
    LastName?: string;
    FirstName?: string;
    Suffix?: string;
    Degree?: string;
    GroupName?: string;
    Address?: string;
    Address2?: string;
    City?: string;
    State?: string;
    Zip?: string;
    VISN?: number;
    Prescribe?: string;
    TW_HN?: string;
    ChoicePC3?: string;
    NPI?: string;
    Specialties?: CommunityProviderVASpecialty[];
    FacilityNameString?: string;
    SCPStrings?: string[];
};

export type ProviderHspc = BaseModel & {
    ProviderId?: number;
    HspcId?: number;
    Provider?: Provider;
};

export type Provider = BaseModel & {
    FacilityType: string;
    MarketName: string;
    FQHC?: FqhcFacility;
    IHS?: IHS;
    AA?: AcademicAffiliation;
    DoD?: DoDInstallation;
    CommunityProvider?: CommunityProvider;
    FqhcFacilityId?: number;
    IHSId?: number;
    AcademicAffiliationId?: number;
    DoDInstallationId?: number;
    CommunityProviderId?: number;
    Name?: string;
    Address1?: string;
    Address2?: string;
    ZipCode?: string;
    City?: string;
    State?: string;
    County?: string;
    ProviderType?: string;
    CurrentProvider?: boolean;
    FutureRelationship?: boolean;
    GeographicAccess?: boolean;
    CapacityStaffing?: boolean;
    Cost?: boolean;
    Quality?: boolean;
    NoInterest?: boolean;
    Other?: boolean;
    OtherText?: string;
    ProviderHspcs?: ProviderHspc[];
    SPCListString?: string;
    DisplayCurrentRelationship?: string;
    SPCIDs?: number[];
    FacilityTypeString?: string;
};

export type OverheadRows = YearsBase & {
    Id?: number;
    RowName?: string;
    RowType?: string;
    Justification?: string;
    SupplyFee: boolean;
    IsExpendable: boolean;
    DisplayRow: boolean;
};

export type PlanCapacity = BaseModelYear &
    YearsBase &
    YearsOrgBase & {
        PlanId: number;
        VaFacilityId?: number;
        Facility?: string;
        HspcWorkloadType: number;
        Justification?: string;
    };

export type PlanSupply = BaseModelYear &
    YearsBase &
    YearsOrgBase & {
        PlanId: number;
        ProviderId: number;
        Provider?: Provider;
        InHouseFacility?: string;
        OutOfMarketFacility?: string;
        HspcWorkloadType: number;
        Justification?: string;
        RowType?: string;
        ProviderName?: string;
        StationNumber?: string;
        Hspc?: string;
    };

export type PlanStaffingSupply = PlanDemand & {
    StaffingType?: string;
};

export type PlanDemand = BaseModelYear &
    YearsBase & {
        SPC?: string;
        SPCGroup?: string;
        MarketName?: string;
        Type?: string;
        HspcWorkloadType?: number;
    };

export type PlanStaffingDemand = PlanDemand & {
    StaffingType?: string;
};

export type CostData = {
    RowType?: string;
    Category?: string;
    Hspc?: string;
    ParentStation?: string;
    Market?: string;
    FacilityUCOverhead?: number;
    FacilityUCResearchAndEducation?: number;
    FacilityUCVASpecificIndirect?: number;
    FacilityUCIndirect?: number;
    FacilityUCFixedDirect?: number;
    FacilityUCSpecificDirect?: number;
    FacilityUCDirect?: number;
    CommunityUCOverhead?: number;
    CommunityUCCareCoordination?: number;
    CommunityUCDAndO?: number;
    CommunityUCPayments?: number;
};

export type StaffingRatio = {
    ProductivityStandard?: number;
    FTERatio?: number;
    FTECount?: number;
    Utilization?: number;
};

export type HspcStaffing = {
    Facility?: string;
    MdData?: StaffingRatio;
    AppData?: StaffingRatio;
    StationNumber?: string;
};

export type PlanDTO = {
    Plan: Plan;
    OverheadRows: OverheadRows[];
    Capacity: PlanCapacity[];
    Supply: PlanSupply[];
    Demand: PlanDemand[];
    StaffingSupply: PlanStaffingSupply[];
    StaffingDemand: PlanStaffingDemand[];
    SpaceSupply: PlanSupply[];
    SpaceDemand: PlanDemand[];
    HspcCosts: CostData[];
    PlanStaffing: HspcStaffing;
};

export type ApprovalUpdate = {
    id: number;
    approvalJustification: string;
    approved: boolean;
    questions: ApprovalQuestion[];
};

export function getPlanName(plan: Plan): string {
    return plan?.PlanName || `Plan #${plan.Id}`;
}

export type VideoTutorial = {
    fileName: string;
    key: string;
    label: string;
    src: string;
    captionSrc: string;
    transcriptSrc: string;
};

import React, { useEffect, useState } from 'react';
import { Alert, Fade } from 'react-bootstrap';

import styled from 'styled-components';

type HSPAAlertProps = {
    timeout?: number;
    children?: React.ReactNode;
    onHide?: () => void;
    variant?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'dark'
        | 'light';
};

const SensitiveDiv = styled.div`
    pointer-events: auto;
`;

/**
 * HSPAAlert is a component that fades in a message using a specific Bootstrap variant.  After a
 * timeout (default 5 seconds), the alert fades back out.  The user can also close the alert early.
 *
 * @param props - props passed into this component
 * @param props.timeout - the lifetime of the alert
 * @param props.children - content to display within the alert
 * @param props.onHide - called when the alert has been completely hidden
 * @param props.variant - the type of alert to show, affects color of alert
 * @returns the alert
 */
const HSPAAlert = ({
    timeout = 30000,
    children,
    onHide = () => {},
    variant
}: HSPAAlertProps): JSX.Element => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(() => setShow(false), timeout);
    }, [timeout, setShow]);

    return (
        <Fade appear in={show} onExited={() => onHide()} unmountOnExit timeout={5000}>
            <SensitiveDiv>
                <Alert
                    variant={variant}
                    dismissible
                    onClose={() => setShow(false)}
                    transition={Fade}
                >
                    {children}
                </Alert>
            </SensitiveDiv>
        </Fade>
    );
};

export default HSPAAlert;

import './style.css';

import React from 'react';
import FontAwesome from 'react-fontawesome';

import proptypes from 'prop-types';

import { Field } from 'redux-form';

export default class TextboxField extends React.Component {
    render() {
        return (
            <Field
                name={this.props.name}
                label={this.props.label}
                placeholder={
                    this.props.placeholder === ''
                        ? 'Enter ' + this.props.label.toLowerCase() + '...'
                        : this.props.placeholder
                }
                disabled={this.props.disabled}
                labelPosition={this.props.labelPosition}
                inputPosition={this.props.inputPosition}
                defaultValue={this.props.defaultValue}
                type="text"
                loading={this.props.loading}
                component={TextboxField.renderTextboxField}
            />
        );
    }
    static renderTextboxField(props) {
        const isValidData = !props.meta.error;
        const inputClassNames = ['form-control'];

        if (props.meta.touched) {
            inputClassNames.push(isValidData ? 'validFormInput' : 'invalidFormInput');
        }

        const errorId = `${props.input.name}-error`;
        const invalid = props.meta.touched && props.meta.error;

        return (
            <div className={'form-group row'}>
                <div className={'col-sm-' + props.labelPosition + ' col-form-label'}>
                    <strong>{props.label + ':'}</strong>
                </div>
                <div className={'col-sm-' + props.inputPosition}>
                    {props.defaultValue ? (
                        <input
                            type="text"
                            aria-invalid={invalid}
                            aria-label={props.label}
                            aria-describedby={invalid ? errorId : null}
                            className={inputClassNames.join(' ')}
                            {...props.input}
                            id={props.input.name}
                            disabled={props.disabled}
                            placeholder={props.placeholder}
                            value={props.defaultValue}
                        />
                    ) : (
                        <input
                            type="text"
                            aria-invalid={invalid}
                            aria-label={props.label}
                            aria-describedby={invalid ? errorId : null}
                            className={inputClassNames.join(' ')}
                            {...props.input}
                            id={props.input.name}
                            disabled={props.disabled}
                            placeholder={props.placeholder}
                        />
                    )}
                    {props.loading === true ? (
                        <div className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </div>
                    ) : null}
                    <div id={errorId} className="invalid-feedback d-block">
                        {invalid ? props.meta.error : null}
                    </div>
                </div>
            </div>
        );
    }
    static propTypes = {
        name: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        defaultValue: proptypes.string,
        disabled: proptypes.bool,
        loading: proptypes.bool
    };
}
TextboxField.defaultProps = {
    placeholder: '',
    disabled: false,
    defaultValue: null,
    labelPosition: 2,
    inputPosition: 10,
    loading: false
};

import React from 'react';

import styled from 'styled-components/macro';

const StyledLink = styled.a`
    position: fixed;
    display: flex;
    align-items: center;
    padding: 10px;
    top: -1000px;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    z-index: 100;
    color: #303030;

    &:focus {
        top: 0;
        text-decoration: none;
    }
`;

type SkipLinkProps = {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * A link that hides until it has focus.  This is used to assist keyboard navigation to skip over
 * menu content.
 *
 * @param props - props passed into this component
 * @param props.onClick - the onClick callback to use when the link is clicked.
 * @returns the link element
 */
const SkipLink = ({ onClick }: SkipLinkProps): JSX.Element => (
    <StyledLink onClick={onClick} href="javascript:void(0)">
        Skip to main content
    </StyledLink>
);

export default SkipLink;

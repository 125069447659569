import './style.css';

import React from 'react';
import FontAwesome from 'react-fontawesome';
import Select from 'react-select';

import _filter from 'lodash/filter';
import _map from 'lodash/map';

import PropTypes from 'prop-types';

import { Field } from 'redux-form';

/**
 * MultiSelectField component for rendering a multi-select field in Redux Forms.
 *
 * @param root0 - The properties passed to the component.
 * @param root0.name - The name of the field.
 * @param root0.label - The label of the field.
 * @param root0.options - The options for the select component.
 * @param root0.valueField - The field to use for the value.
 * @param root0.value - The value of the field.
 * @param root0.placeholder - The placeholder for the select component.
 * @param root0.disabled - Whether the field is disabled.
 * @param root0.delimiter - The delimiter for the select component.
 * @param root0.labelPosition - The position of the label.
 * @param root0.inputPosition - The position of the input.
 * @param root0.loading - Whether the field is loading.
 * @param root0.onChangeFunction - The custom onChange function.
 * @returns The rendered multi-select field.
 */
// eslint-disable-next-line max-lines-per-function
const MultiSelectField = ({
    name,
    label,
    options,
    valueField,
    value,
    placeholder,
    disabled,
    delimiter,
    labelPosition,
    inputPosition,
    loading,
    onChangeFunction
}) => {
    /**
     * Handles the change event for the multi-select field.
     *
     * @param inputName - The name of the input.
     * @param onChange - The onChange function.
     * @param newValue - The new value of the multi-select field.
     */
    const handleChange = (inputName, onChange, newValue) => {
        if (onChangeFunction) {
            onChangeFunction(newValue, inputName);
        } else if (onChange) {
            onChange(newValue);
        }
    };

    /**
     * Gets the simple options for the select component.
     *
     * @param selectOptions - The options for the select component.
     * @param vf - The field to use for the value.
     * @returns The simple options.
     */
    const getSimpleOptions = (selectOptions, vf) =>
        _map(selectOptions, (item) => ({
            label: item[vf],
            value: item[vf],
            data: item
        }));

    /**
     * Gets the validation state for the select component.
     *
     * @param touched - Whether the field has been touched.
     * @param error - The error message if the field has an error.
     * @returns The validation state.
     */
    const getValidationState = (touched, error) => {
        if (touched) {
            if (error) {
                return 'invalidDropdown';
            }
            return 'validDropdown';
        }
        return '';
    };

    /**
     * Renders the multi-select field.
     *
     * @param fieldProps - The properties passed to the component.
     * @param fieldProps.input - The input properties.
     * @param fieldProps.meta - The meta properties.
     * @param fieldProps.disabled - Whether the field is disabled.
     * @param fieldProps.loading - Whether the field is loading.
     * @param fieldProps.label - The label of the field.
     * @param fieldProps.labelPosition - The position of the label.
     * @param fieldProps.inputPosition - The position of the input.
     * @param fieldProps.options - The options for the select component.
     * @param fieldProps.placeholder - The placeholder for the select component.
     * @param fieldProps.delimiter - The delimiter for the select component.
     * @param fieldProps.valueField - The field to use for the value.
     * @param fieldProps.meta.touched - Whether the field has been touched.
     * @param fieldProps.meta.error - The error message if the field has an error.
     * @returns The rendered multi-select field.
     */
    // eslint-disable-next-line max-lines-per-function
    const RenderMultiSelect = ({
        labelPosition: renderLabelPosition,
        label: renderLabel,
        inputPosition: renderInputPosition,
        input,
        disabled: renderDisabled,
        placeholder: renderPlaceholder,
        delimiter: renderDelimiter,
        loading: renderLoading,
        options: renderOptions,
        valueField: renderValueField,
        meta: { touched, error }
    }) => {
        const simpleOptions = getSimpleOptions(renderOptions, renderValueField);
        const validationState = getValidationState(touched, error);
        const hasError = touched && error;
        const errorId = `${input.name}-error`;

        return (
            <div className="form-group row">
                <div className={`col-sm-${renderLabelPosition} col-form-label`}>
                    <strong>{`${renderLabel}:`}</strong>
                </div>
                <div className={`col-sm-${renderInputPosition}`}>
                    <Select
                        {...input}
                        className={validationState}
                        aria-label={renderLabel}
                        closeMenuOnSelect={false}
                        isDisabled={renderDisabled}
                        isMulti
                        options={simpleOptions}
                        value={_filter(
                            simpleOptions,
                            (option) =>
                                _map(input.value, (item) => item.value).indexOf(option.value) >= 0
                        )}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={renderPlaceholder}
                        delimiter={renderDelimiter}
                        aria-invalid={hasError}
                        aria-errormessage={errorId}
                        onChange={(newValue) => handleChange(input.name, input.onChange, newValue)}
                    />
                    {renderLoading === true ? (
                        <div className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </div>
                    ) : null}
                    <span id={errorId} className="invalid-feedback d-block">
                        {hasError ? error : null}
                    </span>
                </div>
            </div>
        );
    };

    RenderMultiSelect.propTypes = {
        labelPosition: PropTypes.number,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        inputPosition: PropTypes.number,
        input: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
            name: PropTypes.string,
            onChange: PropTypes.func
        }).isRequired,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        delimiter: PropTypes.string,
        loading: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({})),
        valueField: PropTypes.string,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string
        })
    };

    return (
        <Field
            name={name}
            label={label}
            aria-label={label}
            options={options}
            displayField={valueField}
            valueField={valueField}
            value={value}
            placeholder={placeholder === '' ? `Enter ${label.toLowerCase()}...` : placeholder}
            disabled={disabled}
            delimiter={delimiter}
            labelPosition={labelPosition}
            inputPosition={inputPosition}
            loading={loading}
            component={RenderMultiSelect}
            onChangeFunction={onChangeFunction}
        />
    );
};

MultiSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    valueField: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    delimiter: PropTypes.string,
    labelPosition: PropTypes.number,
    inputPosition: PropTypes.number,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onChangeFunction: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

MultiSelectField.defaultProps = {
    placeholder: 'None Selected...',
    disabled: false,
    delimiter: '; ',
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    loading: false,
    onChangeFunction: null
};

export default MultiSelectField;

/**
 * A modal dialog displaying the change log information.
 * @author Brandan D. Reed
 * @author Anthony P. Pancerella
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import DDF_Enhancements from 'Content/images/HSPA_5_1_DDF_Enhancements.png';
import General_Enhancements from 'Content/images/HSPA_5_1_General_Enhancements.png';
import Match_Enhancements from 'Content/images/HSPA_5_1_Match_Enhancements.png';

class SAChangeLogModal extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <Modal size="lg" show={this.props.show} onHide={this.handleHide}>
                        <Modal.Header closeButton>
                            <Modal.Title tabIndex={0}>Changelog</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <strong>
                                HSPA Version 7.0, release on 05/29/2024, contains the following
                                enchancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    HSPA Tutorial videos are now hosted in a more accessible
                                    location that does not require requesting access for each
                                    individual user.
                                </li>
                                <li>
                                    All EHCPM data in the Strategic Outlook, Environmental Scan,
                                    Understand Demand, Match Capacity &amp; Demand modules has been
                                    updated to Base Year 2022.
                                </li>
                                <li>
                                    The Capital Projects data that is sourced from SCIP was updated
                                    in the Strategic Outlook module to include all projects as of
                                    12/21/2023.
                                </li>
                                <li>
                                    In the Understand Demand module N/A values are now displayed
                                    instead of zeros for sites that have migrated to Cerner.
                                </li>
                                <li>
                                    The Wait Times Report was updated with new trend analysis over
                                    the previous three fiscal years, plus the current year and to
                                    show Community Opt In data.
                                </li>
                                <li>
                                    Specialty Care Capacity data is updated with a new methodology
                                    in the Understand Capacity module that improves the accuracy of
                                    FTE values.
                                </li>
                                <li>
                                    The Match module was updated to improve the naming conventions
                                    for capacity data.
                                </li>
                            </ul>
                            <strong>
                                HSPA Version 6.1, release on 09/29/2023, contains the following
                                enchancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    The data table components have all been replaced with a new
                                    table component. Functionally, they are the same as the old
                                    ones, just the underlying code was replaced because the old
                                    table component was outdated and a potential security risk.
                                </li>
                                <li>FAQ has been updated.</li>
                                <li>Tutorial videos have been updated.</li>
                                <li>Various minor UI fixes.</li>
                            </ul>
                            <strong>Strategic Outlook</strong>
                            <ul>
                                <li>Fixed the Market Assessment Recommendations references.</li>
                                <li>Non-SCIP Capital Projects can now be deleted.</li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    Once a Match Plan is selected, you can now create additional
                                    Capital Projects for said plan.
                                </li>
                                <li>
                                    Deleting a Plan will now delete all associated Capital Projects,
                                    if there are any.
                                </li>
                            </ul>
                            <strong>
                                HSPA Version 6.0, released on 03/06/23, contains the following
                                enhancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    HSPA is deployed to a new{' '}
                                    <a
                                        href="https://hspa.prod.mah.vaec.va.gov/"
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        URL
                                    </a>{' '}
                                    on the va.gov domain.
                                </li>
                                <li>
                                    All EHCPM data in the Environmental Scan, Understand Demand and
                                    Match Capacity & Demand modules has been updated for Base Year
                                    2022.
                                </li>
                                <li>
                                    Accommodations made for the COVID-19 actual years in the EHCPM
                                    BY20 model have been reversed.
                                </li>
                                <li>
                                    A new user role for Capital Asset Managers (CAMs) has been added
                                    to the system to facilitate their inclusion in creating capital
                                    projects in HSPA.
                                </li>
                            </ul>
                            <strong>Strategic Outlook</strong>
                            <ul>
                                <li>
                                    The Strategic Outlook module now contains a live feed of Market
                                    Assessment Recommendations in a searchable and filterable
                                    dashboard.
                                </li>
                                <li>
                                    HSPA now has a Capital Projects Dashboard that contains a list
                                    of the current SCIP projects and capital projects created in
                                    HSPA. Each SCIP project includes facility, project type,
                                    estimated completion year, and square footage data.
                                </li>
                                <li>
                                    For capital projects created in HSPA, users have the ability to
                                    review, update and approve projects for submission and
                                    consideration in SCIP.
                                </li>
                                <li>
                                    Capital Projects include a linkage to the Strategic Outlook
                                    measure cards for enrollment and demand, justifications, and
                                    questions to support user analysis.
                                </li>
                                <li>
                                    VISN Planner and CAM user roles have the ability to approve
                                    capital projects to be considered in SCIP.
                                </li>
                                <li>
                                    A new VISN/National DD&F Report is available that aggregates
                                    many of the market-based DD&F slides to these higher levels.
                                </li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    The Understand Demand module has a new Wait Times Report for new
                                    and established patients at a parent facility level.
                                </li>
                                <li>A new data definitions document for BY2021 is included.</li>
                            </ul>
                            <strong>Understand Capacity</strong>
                            <ul>
                                <li>
                                    The capacity data for Primary Care, Specialty Care and
                                    Ambulatory Care has been updated to the current year.
                                </li>
                                <li>
                                    External providers from Academic Affiliates, DoD MTFs, FQHC and
                                    IHS facilities have been updated.
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    The Match Capacity/Demand module has an updated user interface
                                    for creating HSPC based plans for analysis.
                                </li>
                                <li>
                                    The module now supports multi-option scenario planning, which
                                    enables users to create one or more options of a plan for a
                                    single HSPC. Each option is studied independently, then analyzed
                                    to select a final option for that HSPC.
                                </li>
                                <li>Plans can now be filtered by plan name and type.</li>
                                <li>
                                    After a plan option is finalized, users can create a new Capital
                                    Project to implement the changes in the plan.
                                </li>
                                <li>
                                    Each Capital Project created in HSPA follows a consistent format
                                    to those in SCIP, including parent facilities and ability to
                                    create Capital Projects that combine more than one HSPC.
                                </li>
                            </ul>
                            <strong>
                                HSPA Version 5.1, released on 06/15/22, contains the following
                                enhancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    A set of updated user help guides for each HSPA module has been
                                    added. Within each module, users can now access the associated
                                    user guide by using the Application drop-down menu and selecting
                                    the module’s help guide at the bottom of the menu.
                                    <br />
                                    <img
                                        src={General_Enhancements}
                                        alt="General Tab Enhancements"
                                    ></img>
                                </li>
                                <li>
                                    HSPA has been updated to better support screen readers and for
                                    general 508 compliance.
                                </li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    The VISN-Level Workload Allocation Matrix has been disabled. An
                                    improved tool will be available in the future version.
                                </li>
                            </ul>
                            <strong>MAHSO Data Discovery &amp; Findings (DDF) Integration</strong>
                            <ul>
                                <li>
                                    For markets with multiple parent sites, DD&F slides have been
                                    reorganized for improved navigation experience. To streamline
                                    topical subgroupings, the facility slides are now displayed as
                                    third row of tabs.
                                    <br />
                                    <img src={DDF_Enhancements} alt="DDF Tab Enhancements"></img>
                                </li>
                            </ul>
                            <strong>Match Module</strong>
                            <ul>
                                <li>
                                    Facility workload data for 999 and A0 facilities have been
                                    added. In certain markets where the workload attributed to these
                                    facilities is significant, users may see a noticeable increase
                                    in demand for existing Match module plans.
                                </li>
                                <li>
                                    Space and staffing dimensions no longer calculate requirements
                                    using community care, federal partners, and academic affiliates
                                    workload, as it is difficult to dictate staffing/space
                                    requirements for external healthcare providers using established
                                    internal methodologies.
                                </li>
                                <li>
                                    The Space Estimator Tools linked in the Space dimension of the
                                    Match module have been updated to reflect BY 2020 data.
                                </li>
                                <li>
                                    Documentation explaining the background, source, and basic
                                    methodology of the cost data as displayed in the Match module is
                                    now available for users to view. A link to the file has been
                                    added to the Cost dimension of the Match module.
                                    <br />
                                    <img
                                        src={Match_Enhancements}
                                        alt="Match Tab Enhancements"
                                    ></img>
                                </li>
                            </ul>
                            <strong>
                                HSPA Version 5.0, released on 11/8/21, contains the following
                                enhancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    All EHCPM data found throughout the system in Environmental
                                    Scan, Understand Demand and Match modules was updated to Base
                                    Year 2022.
                                </li>
                                <li>
                                    Typically, the EHCPM is updated annually to reflect emerging
                                    information on the enrollee population and their utilization of
                                    VHA health care. These analyses are generally tied to the most
                                    recent fiscal years of data and establish assumptions that are
                                    based on this recent information. However, given that FY20 is
                                    impacted heavily by the COVID-19 pandemic and presents
                                    utilization and enrollment patterns that are not expected to
                                    continue in the long-term, many EHCPM modeling assumptions were
                                    developed using data through FY19. Additionally, the projection
                                    continues to use FY19 as a start for projection growth. FY21
                                    through FY23 have been removed from the projections due to the
                                    uncertainty involving these years. It is anticipated that the
                                    largest pandemic impacts of deferred and returning care will
                                    subside by FY24.
                                </li>
                                <li>
                                    Due to the situation and limitations described above, changes
                                    have been made throughout HSPA to rely more heavily on the
                                    actual values captured for 2019 instead of 2020.
                                </li>
                            </ul>
                            <strong>Strategic Outlook</strong>
                            <ul>
                                <li>
                                    Strategic Outlook module has been updated to use 11 years of
                                    data (2019-203) to accommodate the COVID-19 related changes
                                    mentioned above.
                                </li>
                                <li>
                                    A new Opportunities Dashboard, which is linked to the MAHSO
                                    Opportunities list, shows the identified Opportunities for each
                                    market. Due to the sensitive nature of these Opportunities, this
                                    enhancement will be disabled until the Opportunities are
                                    approved for general communication.
                                </li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    Review Projected Demand, Patient Origin Tool, Future Market
                                    Trends and Analysis Tools have been updated to use the EHCPM
                                    BY20 model. The data tables within these tabs now show the 2019
                                    actual year instead of 2020, which results in some data tables
                                    showing data for 2019, 2025 (6-year), 2030 (11-year) and 2040
                                    (21-year) changes in demand.
                                </li>
                            </ul>
                            <strong>MAHSO Data Discovery &amp; Findings (DDF) Integration</strong>
                            <ul>
                                <li>
                                    MAHSO DDF slides have been integrated into the HSPA
                                    Environmental Scan, Understand Demand and Understand Capacity
                                    modules. Please note, markets assessed during phase 1 do not
                                    have online versions of the DDF and will be not available in
                                    HSPA at this time.
                                </li>
                            </ul>
                            <strong>Capacity Values</strong>
                            <ul>
                                <li>
                                    Primary Care, Inpatient and Ambulatory care capacity values have
                                    been updated for all VA facilities.
                                </li>
                                <li>
                                    Requirement to distribute the HSPC Group level beds to
                                    individual HSPCs has been removed for Inpatient.
                                </li>
                            </ul>
                            <strong>External Provider Data</strong>
                            <ul>
                                <li>
                                    External provider lists for Academic Affiliates, DOD, Indian
                                    Health Service (HIS), and Federally Qualified Health Centers
                                    (FQHC) facilities that may partner with VA have been updated.
                                </li>
                            </ul>
                            <strong>Match Module</strong>
                            <ul>
                                <li>
                                    A new Cost dimension of the Match module was created to go along
                                    with Workload, Staffing and Space. This new dimension shows the
                                    VA and Community Care costs for projected years 2026 through
                                    2030 based on the workload Supply. All cost data is shown at a
                                    detail level, which includes 7 types of In-House cost and 4 for
                                    Community Care.
                                </li>
                            </ul>
                            <strong>
                                HSPA 4.0, released on 11/16/20, contains the following enhancements:
                            </strong>
                            <br />
                            <strong>General</strong>
                            <ul>
                                <li>
                                    The HSPA application and database were migrated from the
                                    on-premise VSSC hosted environment to a Microsoft Azure Cloud
                                    environment.
                                    <ul>
                                        <li>
                                            To enable this migration, the HSPA user management was
                                            rewritten to support Active Directory integration.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    All EHCPM data found throughout the system in Environmental
                                    Scan, Understand Demand and Match modules was updated to Base
                                    Year 2019.
                                </li>
                                <li>
                                    All facility names in the system were standardized on the
                                    facility naming conventions from EHCPM and MAHSO.
                                </li>
                                <li>
                                    Throughout the system all references for BY17 were changed to
                                    BY19.
                                </li>
                            </ul>
                            <strong>Environmental Scan</strong>
                            <ul>
                                <li>
                                    In the Environmental Scan the Distance Eligible (40 Mile
                                    Veteran) tables were removed.
                                </li>
                                <li>The Strategic Planning Foundations text was updated.</li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    The HSPC Data Definitions in the Understand Demand module were
                                    updated to BY19.
                                </li>
                            </ul>
                            <strong>Understand Capacity</strong>
                            <ul>
                                <li>
                                    Capacity values for Ambulatory and Inpatient care were updated
                                    to BY19.
                                </li>
                                <li>
                                    Text changes for Inpatient Capacity were made to the Understand
                                    Capacity module.
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    A new space planning tool is now available in the Match Module
                                    that calculates the required square feet necessary to deliver
                                    care in each facility.
                                    <ul>
                                        <li>
                                            This new planning dimension can be viewed by selecting
                                            the space tab within a plan view.
                                        </li>
                                        <li>
                                            For specialty and primary care, estimates are calculated
                                            based on staffing.
                                        </li>
                                        <li>
                                            For inpatient care, estimates are calculated based on
                                            number of beds.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    The Match Module Cone of Uncertainty calculations were updated
                                    with new historical data. For HSPCs that do not have historical
                                    data, the Cone of Uncertainty was removed from the
                                    visualization.
                                </li>
                            </ul>
                            <strong>Reporting</strong>
                            <ul>
                                <li>
                                    The Facility Report was updated to include the new space
                                    calculations
                                </li>
                            </ul>
                            <strong>
                                HSPA 3.6, released on 7/31/20, contains the following enhancements:
                            </strong>
                            <br />
                            <strong>Strategic Outlook</strong>
                            <ul>
                                <li>
                                    A full set of HSPC-level projections shown in the metric chart
                                    is displayed in the table below the graph
                                </li>
                            </ul>
                            <strong>Environmental Scan</strong>
                            <ul>
                                <li>
                                    Under Quality & Survey Data, latest Survey of Enrollees (2019)
                                    data is shown
                                </li>
                            </ul>
                            <strong>Understand Capacity</strong>
                            <ul>
                                <li>
                                    Ambulatory care now uses 2019 provider productivity standards
                                    <ul>
                                        <li>
                                            Productivity standards can now be updated at the
                                            facility level
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Primary care capacity is now an aggregate of the Internal
                                    Medicine and Geriatrics HSPCs, which correlates with the primary
                                    care updates in the Match module
                                    <ul>
                                        <li>
                                            Primary care panel capacity can now be updated at the
                                            facility level
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    Provider staffing estimates have been added to primary care and
                                    ambulatory HSPC plans
                                    <ul>
                                        <li>
                                            This is a new planning dimension that can be viewed by
                                            selecting the "Calculate Staffing" button within a plan
                                            view
                                        </li>
                                        <li>
                                            Provider staffing estimates are displayed as the number
                                            of physician (MD) and advanced practice provider (APP)
                                            FTEs
                                        </li>
                                        <li>
                                            For primary care, provider staffing calculations are
                                            based on enrollment projections
                                        </li>
                                        <li>
                                            For ambulatory care, provider staffing calculations are
                                            based on RVU values derived from productivity standards
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Primary care plans are now generated based on enrollment
                                    projections
                                    <ul>
                                        <li>
                                            All primary care HSPCs are now combined into a single
                                            primary care plan
                                        </li>
                                        <li>Existing primary care plans have been deleted</li>
                                    </ul>
                                </li>
                                <li>
                                    Inpatient HSPCs are now measured in Beds instead of Bed Days of
                                    Care
                                </li>
                                <li>
                                    Upper/lower demand thresholds have been removed where unused
                                </li>
                            </ul>
                            <strong>Reporting</strong>
                            <ul>
                                <li>
                                    Added the Facility Report, which shows all HSPC plan data from
                                    the Match module for a specified facility
                                    <ul>
                                        <li>
                                            The data displayed from plans include workload capacity,
                                            planned workload, and planned provider staffing
                                        </li>
                                        <li>
                                            If the facility provides HSPC services and there is no
                                            corresponding plan created in the Match module, the
                                            report will use default plan values for that HSPC
                                            <ul>
                                                <li>
                                                    An indicator is displayed for each HSPC that has
                                                    data pulled from a Match module plan
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <strong>
                                Version 3.5 of the HSPA, which was released on 10/2/19, contains the
                                following enhancements –
                            </strong>
                            <br />
                            <strong>New Features</strong>
                            <ul>
                                <li>
                                    When there are new communications to deliver, HSPA will now show
                                    news when first opening the home page.
                                </li>
                                <li>
                                    The Strategic Outlook module now has better filtering
                                    capabilities.
                                </li>
                                <li>
                                    The Match Capacity/Demand module now shows plan details and
                                    chart above the table.
                                </li>
                            </ul>
                            <strong>Bug Fixes/Updates</strong>
                            <ul>
                                <li>Updated the list of facilities.</li>
                                <li>Realigned Houston market from VISN 17 to VISN 16.</li>
                            </ul>
                            <strong>
                                Version 3.0 of the HSPA, which was released on 3/5/19, contains the
                                following enhancements –
                            </strong>
                            <br />
                            <strong>General Changes</strong>
                            <ul>
                                <li>
                                    All data contained within HSPA is updated to the latest
                                    available sources, including the BY2017 Enrollee Healthcare
                                    Projection Model (EHCPM).
                                </li>
                                <li>
                                    HSPA has a completely new look and feel that is significantly
                                    quicker and more interactive. This redeveloped user interface
                                    uses modern technologies to load data into the application as
                                    the user needs it. This allows the users to navigate between the
                                    different modules quickly and without losing their place in
                                    another module.{' '}
                                </li>
                            </ul>
                            <strong>Strategic Outlook</strong>
                            <ul>
                                <li>
                                    The Strategic Outlook is a new module designed to provide visual
                                    markers for overall utilization and demographic measures within
                                    a VISN or market.{' '}
                                </li>
                                <li>
                                    Each “card” is a distinct measurement that shows the direction
                                    of change, the amount of change per a measure for Demographics –
                                    Enrollment, Eligible, Gender and OEF/OIF/OND, and for HSPC Group
                                    level utilization.
                                </li>
                                <li>
                                    Under Demographics, clicking on a card brings up a line chart of
                                    either Priority Groups or Age Groups
                                </li>
                                <li>
                                    Under Demand, clicking on a card brings up a line chart of
                                    either the individual HSPCs or Facilities that are within that
                                    HSPC.
                                </li>
                                <li>
                                    Each chart is interactive. Clicking on an item in the legend
                                    will hide or display that item on the chart and cause the chart
                                    to update in real time.
                                </li>
                            </ul>
                            <strong>Environmental Scan</strong>
                            <ul>
                                <li>
                                    Updated the Strategic Planning Foundations include VHA
                                    Priorities, Strategic Policy and Geography and Rurality tabs.
                                </li>
                                <li>
                                    Thanks to PPSG, the Dynamic Mapping tab is updated to a new
                                    interface and data
                                </li>
                                <li>
                                    Under Geography and Rurality, added two tables for 40-Mile
                                    Veterans, by enrollment and Eligible to Enroll.
                                </li>
                                <li>
                                    Under Quality & Survey Data, added the Strategic Analytics for
                                    Improvement and Learning (SAIL) and Hospital Compare tabs.
                                </li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    Based on user feedback we added Submarket data in the Review
                                    Projected Demand tables. This can be accessed by clicking on the
                                    “Sub-Market Data” link at the top of the table.
                                </li>
                                <li>
                                    In BY17 of the EHCPM we have access to three years of “Actuals”
                                    data for FY15, FY16 and FY17. HSPA now includes these under
                                    Review Projected Demand and Future Market Trends.
                                </li>
                                <li>
                                    To display or hide the Actuals columns, there is a drop down
                                    above tables that allow selecting which columns to display. By
                                    default, HSPA will only display the Actual for FY17 to conserve
                                    space on the page.
                                </li>
                                <li>
                                    Updated the Wait Times data source to pull in data in real time
                                    from a VSSC Wait Times cube.
                                </li>
                            </ul>
                            <strong>Understand Capacity</strong>
                            <ul>
                                <li>
                                    Added In-House Ambulatory Care Capacity table to VA Health Care
                                    Resources. This includes many of the outpatient HSPCs that are
                                    found within HSPA, and are used to populate the capacity values
                                    in the Match module.
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    Updated the design of the module to be more user friendly and to
                                    automatically update the bottom chart in real time as the table
                                    changes.
                                </li>
                            </ul>
                            <strong>Reporting</strong>
                            <ul>
                                <li>
                                    Redesigned the Reporting module to include a more user friendly
                                    approach based on a “wizard” look and feel. This now guides the
                                    user through each module to select what should be included in
                                    the report.
                                </li>
                            </ul>
                            <br />
                            <strong>
                                Version 2.0 of the HSPA released on 11/2017 contains the following
                                enhancements -
                            </strong>
                            <br />
                            <strong>General Changes</strong>
                            <ul>
                                <li>
                                    All data within the HSPA is updated to the latest available
                                    sources, this includes the BY2016 Enrollee Healthcare Projection
                                    Model (EHCPM).
                                </li>
                                <li>
                                    Throughout the application Health Service Planning Categories
                                    (HSPC) have replaced the previous Strategic Planning Categories
                                    (SPC).
                                </li>
                                <li>
                                    All Utilization projections now contain Community Care as well
                                    as In House values.
                                </li>
                                <li>Twenty Year projections are now present</li>
                            </ul>
                            <strong>Environmental Scan</strong>
                            <ul>
                                <li>The Strategic Planning Foundations are updated</li>
                                <li>
                                    The Dynamic Mapping tool within Geography and Rurality is
                                    updated with a new mapping tool. Users now have the option to
                                    save the location of the map they are viewing and provide a
                                    title for the map.
                                </li>
                                <li>
                                    Under Demographic Data five new data tables are available that
                                    display county level data for Veteran Eligible, Veteran
                                    Enrollment, Market Share Percentage, Special Population Gender,
                                    Special Population OEF/OIF/OND
                                </li>
                            </ul>
                            <strong>Understand Demand</strong>
                            <ul>
                                <li>
                                    On the Review Projected Demand and Future Market Trends tabs,
                                    Relative Value Units (RVUs) are now available for outpatient
                                    HSPCs.
                                </li>
                                <li>
                                    Drill downs are available for Market Utilization, Facility
                                    Utilization, HSPC Group Utilization, and HSPC Utilization that
                                    show RVUs within the same table as Utilization.
                                </li>
                                <li>
                                    The Patient Origin Tool and Analysis Tools now have a feature to
                                    "Save For Report". This will save the current configuration of
                                    the tool and replicate the table in the Reporting module.
                                </li>
                                <li>
                                    The Analysis Tool has a new visualization that contains 20 years
                                    of data points.
                                </li>
                            </ul>
                            <strong>Understand Capacity</strong>
                            <ul>
                                <li>
                                    Primary Care Capacity values are updated to include Facility
                                    based Panelized Enrollees, Panel Capacity, and RVU Production
                                    Capacity FY16 National Average.
                                </li>
                                <li>
                                    Inpatient Capacity values now include Average Daily Census, Beds
                                    as well as Bed Days of Care. The number of Beds for an HSPC at a
                                    Facility are now editable by users. Any changes to Inpatient
                                    Capacity will be reflected in new Plans created in the Match
                                    Capacity/Demand Module
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <ul>
                                <li>
                                    Creating a Plan in the Match Capacity/Demand Module will
                                    automatically use RVUs for Ambulatory Care and Utilization for
                                    Inpatient Care HSPCs
                                </li>
                                <li>
                                    For Ambulatory HSPCs a secondary chart is available that shows
                                    Demand, Capacity & Supply trends for Utilization.
                                </li>
                                <li>
                                    Community Care Demand data has been added to the system and is
                                    available under Demand
                                </li>
                                <li>
                                    A "Reset" feature is now available that resets a specific row of
                                    data to the original value.
                                </li>
                            </ul>
                            <strong>Reporting</strong>
                            <ul>
                                <li>
                                    A completely new Reporting Module is now available, which allows
                                    for dynamic creation of reports based on a user's selection of
                                    specific tables and HSPCs.
                                </li>
                                <li>
                                    Reports can be saved for future use, or edited and re-exported
                                </li>
                                <li>
                                    Template Reports are available - one for each module - which can
                                    be exported as is, or copied and edited to configure a report
                                    for that Market.
                                </li>
                            </ul>
                            <br />
                            <strong>
                                HSPA V1.1 contains the following application enhancements:
                            </strong>
                            <br />
                            <strong>General Changes</strong>
                            <ul>
                                <li>
                                    All facility names will be displayed as the VAST (VHA Site
                                    Tracking) common name. The common name is also known as the
                                    location description.
                                </li>
                                <li>
                                    All data tables have been converted from GridViews to
                                    DataTables. This change provides the user with column sorting
                                    options.
                                </li>
                                <li>
                                    A Changelog has been added as a selection to the Site Actions
                                    drop down. The Changelog serves as an announcement feed for the
                                    major additions and minor adjustments for the corresponding HSPA
                                    version.
                                </li>
                            </ul>
                            <strong>Environmental Scan</strong>
                            <ul>
                                <li>
                                    Under the Geography and Rurality Banner, the Rurality Data Tab
                                    displays rurality analysis tables that details end-of-year
                                    access. A new (Market) Sector column has been added to the
                                    rurality analysis table.
                                </li>
                            </ul>
                            <strong>Understand Demand Module</strong>
                            <ul>
                                <li>
                                    The CNH Long and Short LTSS Inpatient Strategic Planning
                                    Categories have been removed from the base year data.
                                </li>
                            </ul>
                            <strong>Understand Capacity Module</strong>
                            <ul>
                                <li>
                                    All current Community Providers have been loaded to the
                                    application and users can select from a list of providers under
                                    the Add/Edit Provider banner and tab. When selected from the
                                    drop down list. The provider’s name will appear as a health care
                                    supplier in the Matching Module.
                                </li>
                            </ul>
                            <strong>Match Capacity/Demand</strong>
                            <br />
                            <i>NO Changes</i>
                            <br />
                            <strong>Reporting</strong>
                            <br />
                            <i>NO Changes/NO functions available</i>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
    handleHide = () => {
        this.props.changelogModalToggleShow();
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        changelogModalToggleShow: () => dispatch.ChangelogModel.toggle()
    };
};

const mapStateToProps = (state) => ({
    show: state.ChangelogModel.show
});

export default connect(mapStateToProps, mapDispatchToProps)(SAChangeLogModal);

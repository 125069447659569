import './style.css';

/**
 * Container modal for manage users modal.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { isAdministrator } from 'Modules/UserSession/selector';

import AllSystemUsers from './SAAllSystemUsers/AllSystemUsers';
import AddEditUser from './SAManageUserForm';
import UsersInNetwork from './SAUsersInNetwork';

class SAManageUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    handleHide = () => {
        this.props.manageUsersModalToggleShow();
    };

    render() {
        const tabs = [
            {
                key: 0,
                label: 'Add/Edit User'
            },
            {
                key: 1,
                label: this.props.isAdmin ? 'Manage Users' : 'User in your Network'
            },
            {
                key: 2,
                label: 'View All System Users',
                hidden: !!this.props.isAdmin
            }
        ];

        return (
            <div>
                <div>
                    <Modal size="lg" show={this.props.show} onHide={this.handleHide}>
                        <Modal.Header closeButton>
                            <Modal.Title tabIndex={0}>Manage Users</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ModuleNavigation
                                activeTab={this.state.activeTab}
                                tabs={tabs}
                                handleOnClick={this.changeTab}
                            />
                            <div style={{ paddingTop: 15 }}>
                                {this.state.activeTab === 0 ? (
                                    <AddEditUser changeTab={this.changeTab} />
                                ) : null}
                                {this.state.activeTab === 1 ? (
                                    <UsersInNetwork
                                        changeTab={this.changeTab}
                                        isAdmin={isAdministrator}
                                    />
                                ) : null}
                                {this.state.activeTab === 2 ? <AllSystemUsers /> : null}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        manageUsersModalToggleShow: dispatch.ManageUserModel.toggle
    };
};

const mapStateToProps = (state) => ({
    show: state.ManageUserModel.show,
    isAdmin: isAdministrator(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SAManageUserModal);

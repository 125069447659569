import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import _find from 'lodash/find';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getGraphMetrics } from 'Modules/StrategicOutlook/Services/selector';
import { getCurrentVISN } from 'Modules/UserSession/selector';
import { percentFormatter } from 'Utilities/formatters';

import SOMetricChart from './SOMetricChart';
import { MetricColumnDefs } from './TableOutline';

const mergeById = (a1, a2) =>
    a1.map((itm) => ({
        visible: true,
        ...a2.find((item) => item.name === itm.HspType && item),
        ...itm
    }));

const sortMetrics = (valueA, valueB, order, dataField, rowA, rowB) => {
    if (rowA.IsTotal) {
        return -1;
    }
    if (rowB.IsTotal) {
        return 1;
    }

    var difference = 1;
    if (typeof valueA === 'number') difference = Math.abs(valueA) - Math.abs(valueB);
    else difference = valueA > valueB ? 1 : -1;

    return order === 'asc' ? difference : -difference;
};

const getNextSortOrder = (row, columnId) => {
    const cells = row.getAllCells();
    let column;
    for(const element of cells) {
        if (element.column.id === columnId) {
            column = element.column;
            break;
        }
    }

    return column.getNextSortingOrder();
}

const customSortMetrics = (rowA, rowB, columnId) => {
    const sortOrder = getNextSortOrder(rowA, columnId);
    if (rowA.original.IsTotal === true) {
        if (rowA.index <= 0 || rowB.original.IsTotal === true) {
            return 0;
        }
        return sortOrder === 'asc' ? 1 : -1;
    }
    
    if (rowB.original.IsTotal === true) {
        if (rowB.index <= 0 || rowA.original.IsTotal === true) {
            return 0;
        }
        return sortOrder === 'asc' ? -1 : 1;
    }

    let result = 1;
    const valueA = rowA.getValue(columnId);
    const valueB = rowB.getValue(columnId);

    if (typeof valueA === 'number') { 
        result = Math.abs(valueA) - Math.abs(valueB);
    } else {
        result = valueA > valueB ? 1 : -1;
    }

    return sortOrder === 'asc' ? result : -result;
};

const rowClasses = (row) => (row.IsTotal ? 'table-success' : '');

const SOMetricDetailsModal = () => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState('tab1');
    const hspType = useSelector((state) =>
        (state.MetricPopupModel.data === undefined || state.MetricPopupModel.data.length === 0
            ? ''
            : state.MetricPopupModel.data.HspType)
    );

    const groupColumnName = () => {
        if (hspType === 'Enrollment') {
            return currentTab === 'tab1' ? 'Priority Group' : 'Age Group';
        }

        if (hspType === 'Eligible') {
            return currentTab === 'tab1' ? 'Priority Group' : 'Age Group';
        }

        if (hspType === 'Enrollment_Gender') {
            return 'Gender and Age Group';
        }

        if (hspType === 'Enrollment_Special_Conflict') {
            return 'Age Group';
        }
        return currentTab === 'tab1' ? 'HSPC' : 'Facility';
    };

    const [seriesVisibilities, setSeriesVisibilities] = useState([]);
    const [groupName, setGroupName] = useState(() => groupColumnName());
    const modal = useSelector((state) => state.MetricPopupModel);

    useEffect(() => {
        setGroupName(() => groupColumnName());
    }, [hspType, currentTab]);

    const chart = useRef();
    const setSeriesVisibility = useCallback(
        (isVisible, name) => {
            _find(chart.current.chart.series, { name }).setVisible(isVisible, true);
        },
        [chart]
    );

    const updateVisibilities = useCallback(() => {
        const visibilities = chart.current.chart.series.map((series) => ({
            name: series.name,
            visible: series.visible
        }));
        setSeriesVisibilities(visibilities);
    }, []);

    const setAllVisibility = useCallback((isVisible) => {
        for (let i = 0; i < chart.current.chart.series.length; i++) {
            chart.current.chart.series[i].setVisible(isVisible, true);
        }
    }, []);

    const currentVisn = useSelector((state) => getCurrentVISN(state));
    const dataArray = useSelector(getGraphMetrics);
    const toggleMetricModal = () => {
        seriesVisibilities.length = 0;
        setCurrentTab('tab1');
        dispatch.MetricPopupModel.reset();
    };

    const tabDisplay = (() => {
        if (hspType === 'Enrollment' || hspType === 'Eligible') {
            return (
                <Nav variant="pills" fill style={{ paddingBottom: 15 }}>
                    <Nav.Item>
                        <Nav.Link
                            href=""
                            onClick={() => setCurrentTab('tab1')}
                            active={currentTab === 'tab1'}
                        >
                            Priority Groups
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            href=""
                            onClick={() => setCurrentTab('tab2')}
                            active={currentTab === 'tab2'}
                        >
                            Age Groups
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            );
        }

        if (hspType !== 'Enrollment_Gender' && hspType !== 'Enrollment_Special_Conflict') {
            return (
                <Nav variant="pills" fill style={{ paddingBottom: 15 }}>
                    <Nav.Item>
                        <Nav.Link
                            href=""
                            onClick={() => setCurrentTab('tab1')}
                            active={currentTab === 'tab1'}
                        >
                            HSPCs
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            href=""
                            onClick={() => setCurrentTab('tab2')}
                            active={currentTab === 'tab2'}
                        >
                            Facilities
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            );
        }

        return '';
    })();

    const getGraphData = () => {
        if (hspType === 'Enrollment') {
            return currentTab === 'tab1' ? modal.graph1.data : modal.graph2.data;
        }

        if (hspType === 'Eligible') {
            return currentTab === 'tab1' ? modal.graph1.data : modal.graph2.data;
        }

        if (hspType === 'Enrollment_Gender') {
            return modal.graph1.data;
        }

        if (hspType === 'Enrollment_Special_Conflict') {
            return modal.graph1.data;
        }
        return currentTab === 'tab1' ? modal.graph1.data : modal.graph2.data;
    };

    const columnHeaderCheckbox = () => (
        <div>
            <span>
                <Form.Switch
                    id="header-switch"
                    label=""
                    onChange={(event) => setAllVisibility(event.target.checked)}
                    checked={!seriesVisibilities.some((e) => e.visible === false)}
                />
            </span>
        </div>
    );

    const rowCheckBox = (cell, row) => (
        <div>
            <span>
                <Form.Switch
                    id={row.HspType}
                    label=""
                    onChange={(event) => setSeriesVisibility(event.target.checked, row.HspType)}
                    checked={cell}
                />
            </span>
        </div>
    );

    const actualColumn = () => (
        <div>
            <span>Actual</span>
            <div style={{ fontSize: 'small', color: 'gray', marginTop: '5px' }}>
                Target: {percentFormatter(modal.data.MeasureTarget)}
            </div>
        </div>
    );

    const tableData = mergeById(
        currentTab === 'tab1' || dataArray.graph2.length === 0
            ? dataArray.graph1 || []
            : dataArray.graph2 || [],
        seriesVisibilities
    );

    const defaultSorted = [{ dataField: 'MeasureActual', order: 'desc' }];
    const defaultSortedDef = [{ id: 'MeasureActual', desc: true }];

    return (
        <Modal size="lg" show={modal.show} onHide={toggleMetricModal}>
            <Modal.Header closeButton>
                <Modal.Title tabIndex={0}>Metric Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{tabDisplay}</div>
                <SOMetricChart
                    data={getGraphData()}
                    onVisibilityChange={updateVisibilities}
                    useYearOffset={false} //{modal.data.ProjectionTypeString !== 'Enrollment'}
                    ref={chart}
                />
                <div>
                    {/* TODO: Use flexbox instead */}
                    <header
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            marginBottom: '20px'
                        }}
                    >
                        <b>Market: </b> {modal.data.MarketName}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Projection Type: </b> {modal.data.ProjectionTypeString}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Base Year: </b> {modal.data.Year}
                    </header>
                    {/* {modal.data.ProjectionTypeString !== 'Enrollment' ? (
                        <Alert role="note" variant="warning" className="mt-2">
                            <FontAwesomeIcon icon={faInfoCircle} fixedWidth /> Due to
                            caused by the COVID-19 pandemic, data for 2022-2023 is invalid and will
                            not be shown.
                        </Alert>
                    ) : null} */}
                    <ReactDataTable
                        defaultSorted={defaultSortedDef}
                        keyValue="Id"
                        list={tableData}
                        columns={MetricColumnDefs(
                            rowCheckBox,
                            columnHeaderCheckbox,
                            groupName,
                            actualColumn,
                            customSortMetrics
                        )}
                        csvFileName={`${currentVisn.VisnCode}-Projections.csv`}
                        isPageable={false}
                        rowClasses={rowClasses}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SOMetricDetailsModal;

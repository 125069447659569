import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components/macro';

import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';

const editableTypes = ['General', 'Workload'];

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    > * {
        margin-left: 4px;
        margin-right: 4px;
    }
`;

/**
 * Render the buttons for the Match module.
 *
 * @param {object} props - props passed into this component
 * @param {string} props.type - the currently displayed plan data type, determines whether buttons
 * are editable or not
 * @param {number} props.plan - the current plan
 * @param {string} props.marketName - The name of the market.
 * @returns {React.ReactNode} the rendered buttons
 */
const MatchModuleButtons = ({ type, plan }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const market = useSelector(getCurrentMarketName);
    const canEdit = useSelector(canEditVISN);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const handleOpenDeletePrompt = () => setShowDeletePrompt(true);
    const handleCloseDeletePrompt = () => setShowDeletePrompt(false);

    // Delete plan by ID
    const deletePlan = () =>
        dispatch.ManagePlansModel.deletePlanAsync({ id: plan.Id, marketName: market }).then(() =>
            history.push('/HSPA/Match')
        );

    const modalBody =
        plan?.CapitalProjectPlans && plan.CapitalProjectPlans.length > 0 ? (
            <>
                <p>
                    Please confirm the deletion of this plan, and the associated capital projects:
                </p>
                <ul>
                    {plan.CapitalProjectPlans.map((capitalProjectPlan) => (
                        <li key={capitalProjectPlan.CapitalProject.Id}>
                            {capitalProjectPlan.CapitalProject.ProjectNumber}
                        </li>
                    ))}
                </ul>
            </>
        ) : (
            <p>Are you sure you want to delete this plan?</p>
        );

    const deletePrompt = (
        <Modal
            show={showDeletePrompt}
            backdrop="static"
            onHide={handleCloseDeletePrompt}
            keyboard={false}
        >
            <Modal.Header tabIndex={0}>Delete Plan</Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deletePlan}>Yes</Button>
                <Button variant="secondary" onClick={handleCloseDeletePrompt}>No</Button>
            </Modal.Footer>
        </Modal>
    );

    const isEditableType = editableTypes.includes(type);

    const buttons = [];

    if (isEditableType) {
        buttons.push(
            <Button variant="primary" onClick={dispatch.PlannerModel.toggleProviderModal}>
                Add Provider
            </Button>,
            <Button
                variant="primary"
                disabled={!canEdit}
                onClick={dispatch.PlannerModel.savePlanAsync}
            >
                Save Edits
            </Button>,
            <Button
                variant="warning"
                onClick={() => dispatch.PlannerModel.fetchPlanByIdAsync(plan.Id)}
            >
                Undo Edits
            </Button>
        );
    }

    buttons.push(
        <Button
            color="success"
            type="button"
            onClick={() =>
                dispatch.PlannerModel.generatePlanReportAsync({
                    planId: plan.Id,
                    marketName: market
                })
            }
        >
            Export to Excel
        </Button>
    );

    if (isEditableType && plan?.Id && market) {
        buttons.push(
            <Button variant="danger" onClick={handleOpenDeletePrompt}>
                Delete Plan
            </Button>
        );
    }

    return isEditableType ? (
        <>
            <Container>{buttons}</Container>
            {deletePrompt}
        </>
    ) : (
        <Container>{buttons}</Container>
    );
};

export default MatchModuleButtons;

import React, { useState } from 'react';
import { Col, Dropdown, DropdownButton, Pagination, Row } from 'react-bootstrap';

import { Table } from '@tanstack/react-table';

export type PaginationProps = {
    table: Table<unknown>;
    pageSizes?: number[];
};

/**
 * Pagination component for the Table.
 *
 * @param props - The Pagination properties.
 * @param props.table - The Table.
 * @param props.pageSizes - The list of acceptable page sizes for pagination.
 * @returns - The Pagination component.
 */
const TablePagination = ({ table, pageSizes = [10, 25, 30, 50] }: PaginationProps) => {
    const [currentPageSize, setCurrentPageSize] = useState<number>(
        table.getState().pagination.pageSize
    );

    const pageOptions = table.getPageOptions();
    const currentPageIndex = table.getState().pagination.pageIndex;

    const lastIndex = pageOptions.length - 1;
    let startingIndex = currentPageIndex - 2;

    if (startingIndex < 0) {
        startingIndex = 0;
    }

    let endingIndex = startingIndex + 4;
    if (endingIndex > lastIndex) {
        endingIndex = lastIndex;
        startingIndex = lastIndex - 4;
        if (startingIndex < 0) {
            startingIndex = 0;
        }
    }

    const pages = pageOptions.slice(startingIndex, endingIndex + 1).map((page: number) => (
        <Pagination.Item
            key={page}
            active={page === currentPageIndex}
            onClick={() => table.setPageIndex(page)}
        >
            {page + 1}
        </Pagination.Item>
    ));

    return (
        <Row>
            <Col sm>
                <DropdownButton
                    variant="secondary"
                    title={currentPageSize}
                    aria-label={`Current row count is ${currentPageSize}`}
                >
                    {pageSizes.map((pageSize) => (
                        <Dropdown.Item
                            key={pageSize}
                            value={pageSize}
                            aria-label={`Set row count to ${pageSize}`}
                            onClick={() => {
                                table.setPageSize(pageSize);
                                setCurrentPageSize(pageSize);
                            }}
                        >
                            {pageSize}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </Col>
            <Col sm>
                <Pagination style={{ float: 'right' }}>
                    {startingIndex > 0 ? (
                        <Pagination.First onClick={() => table.setPageIndex(0)} />
                    ) : null}
                    {table.getCanPreviousPage() ? (
                        <Pagination.Prev onClick={() => table.previousPage()} />
                    ) : null}
                    {pages}
                    {table.getCanNextPage() ? (
                        <Pagination.Next onClick={() => table.nextPage()} />
                    ) : null}
                    {endingIndex < lastIndex ? (
                        <Pagination.Last onClick={() => table.setPageIndex(lastIndex)} />
                    ) : null}
                </Pagination>
            </Col>
        </Row>
    );
};

export default TablePagination;

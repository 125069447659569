import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux';

import { map } from 'lodash';
import _map from 'lodash/map';
import { useHSPADispatch, useHSPASelector } from 'storeTypes';

import { canEditVISN, getCurrentMarketName, getDefaultYear } from 'Modules/UserSession/selector';
import { getPlanName, Plan, PlanDTO } from 'Utilities/types';

import { getPlanData } from '../../Services/selector';
import CreateCapitalProjectModal from './CreateCapitalProjectModal';
import PlanCompareChart from './PlanCompareChart';

type Props = {
    hspc: string;
    plans: Plan[];
};

/**
 * Component that shows a Modal for creating a new Plan.
 *
 * @returns - The component.
 */
const SelectPlanModal = ({ hspc, plans }: Props): JSX.Element => {
    const dispatch = useHSPADispatch();
    const marketName = useSelector(getCurrentMarketName);
    const [showModal, setShowModal] = useState(false);
    const managePlansModel = useHSPASelector((state) => state.ManagePlansModel);
    const hasPermission = useHSPASelector(canEditVISN);
    const [creating, setCreating] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showTextModal, setShowTextModal] = useState(false);
    const [justification, setJustification] = useState('');
    const [showCreateCapitalProject, setShowCreateCapitalProject] = useState(false);
    const [showPromptForProjectCreate, setShowPromptForProjectCreate] = useState(false);
    const [selectedPlanName, setSelectedPlanName] = useState('');
    const selectedPlan = useSelector(getPlanData) as PlanDTO;
    const year = (useHSPASelector(getDefaultYear) as number) || new Date().getUTCFullYear();

    const clearData = () => {
        setShowModal(false);
        setCreating(false);
        setCurrentIndex(0);
        setShowTextModal(false);
        setJustification('');
        setShowCreateCapitalProject(false);
        setShowPromptForProjectCreate(false);
        setSelectedPlanName('');
    };

    const clearDataAndReload = () => {
        clearData();
        dispatch.ManagePlansModel.fetchPlansAsync(marketName);
    };

    useEffect(() => {
        if (!showModal) {
            return;
        }

        const fetchPlan = async (id: number) => {
            await dispatch.PlannerModel.fetchPlanByIdAsync(id);
        };

        if (plans && currentIndex >= 0 && currentIndex < plans.length) {
            setSelectedPlanName(getPlanName(plans[currentIndex]));
            fetchPlan(plans[currentIndex].Id).catch(console.error);
        }
    }, [currentIndex, plans, showModal]);

    const handleSelect = (selectedIndex) => {
        setCurrentIndex(selectedIndex);
        setSelectedPlanName(getPlanName(plans[selectedIndex]));
    };

    /**
     * Handles when the Modal is closed.
     */
    const handleModalClose = () => {
        setShowModal(false);
    };

    /**
     * Handles when the Modal is opened.
     */
    const handleModalOpen = () => setShowModal(true);

    /**
     * Handles when the Modal is closed.
     */
    const handleTextModalClose = () => {
        setShowTextModal(false);
    };

    /**
     * Handles when the Modal is opened.
     */
    const handleTextModalOpen = () => setShowTextModal(true);

    const selectPlan = () => {
        setCreating(true);
        if (currentIndex < 0 || currentIndex >= plans.length) {
            return;
        }

        const plan = plans[currentIndex];
        if (!plan) {
            return null;
        }

        setSelectedPlanName(getPlanName(plan));

        return dispatch.ManagePlansModel.selectPlanOptionAsync({
            planId: plan.Id,
            spcId: plan.HspcId,
            marketName: plan.MarketName,
            year: plan.Year,
            justification: justification
        }).then(() => {
            setCreating(false);
            setShowPromptForProjectCreate(true);
        });
    };

    const handleJustificationChanged = (just: string) => {
        setJustification(just);
    };

    const planSelection = map(plans, (plan: Plan): JSX.Element => {
        const planName = getPlanName(plan);
        return (
            <Carousel.Item key={plan.Id} className="px-5">
                <Row>
                    <Col>
                        <PlanCompareChart title={planName} plan={selectedPlan} year={year} />
                    </Col>
                </Row>
            </Carousel.Item>
        );
    });

    return (
        <>
            <Button
                variant="primary"
                size="sm"
                type="button"
                disabled={!hasPermission || managePlansModel.Plans.loading}
                style={{ borderRadius: '16px' }}
                onClick={handleModalOpen}
            >
                Select Final Option
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                backdrop="static"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title tabIndex={0}>{hspc}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Carousel
                                fade
                                onSlid={handleSelect}
                                interval={null}
                                prevIcon={
                                    <span
                                        aria-hidden="true"
                                        className="carousel-control-prev-icon"
                                        style={{ filter: 'invert(75%)' }}
                                    />
                                }
                                nextIcon={
                                    <span
                                        aria-hidden="true"
                                        className="carousel-control-next-icon"
                                        style={{ filter: 'invert(75%)' }}
                                    />
                                }
                                style={{ margin: 'auto' }}
                            >
                                {planSelection}
                            </Carousel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={clearData}
                        disabled={creating}
                        style={{ borderRadius: '16px' }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        disabled={!hasPermission || creating}
                        style={{ borderRadius: '16px' }}
                        onClick={() => {
                            handleTextModalOpen();
                            handleModalClose();
                        }}
                    >
                        Choose
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showTextModal}
                onHide={clearData}
                backdrop="static"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Modal.Header closeButton>
                        <Modal.Title tabIndex={0}>Justification for {selectedPlanName}</Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={(e) => handleJustificationChanged(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="mt-3">
                    <Button
                        variant="secondary"
                        onClick={clearData}
                        disabled={creating}
                        style={{ borderRadius: '16px' }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        disabled={!hasPermission || creating}
                        style={{ borderRadius: '16px' }}
                        onClick={() => {
                            handleTextModalClose();
                            handleModalClose();
                            selectPlan();
                        }}
                    >
                        {creating ? (
                            <Spinner as="span" animation="border" size="sm" role="status">
                                <span className="sr-only">Submitting...</span>
                            </Spinner>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showPromptForProjectCreate}
                onHide={clearData}
                backdrop="static"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title tabIndex={0}>Create Capital Project?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Would you like to create a Capital Project for the {selectedPlanName} plan?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="button"
                        disabled={!hasPermission || creating}
                        style={{ borderRadius: '16px' }}
                        onClick={() => {
                            setShowPromptForProjectCreate(false);
                            setShowCreateCapitalProject(true);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearDataAndReload}
                        disabled={creating}
                        style={{ borderRadius: '16px' }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <CreateCapitalProjectModal
                showModal={showCreateCapitalProject}
                plan={selectedPlan}
                createFinish={clearDataAndReload}
            />
        </>
    );
};

export default SelectPlanModal;

import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import _map from 'lodash/map';

/**
 * The Report Module container component.
 *
 * @author Brandan D. Reed
 * @author Anthony P. Pancerella
 */
import ModuleNavigation from 'Lib/Tabs/RoutingModuleNavigation';
import HeaderContainer from 'Modules/App/HeaderContainer';
import analytics from 'Utilities/analytics';

import FacilityReportManagement from './FacilityReportManagement';
import ReportDashboard from './ReportDashboard';
import ReportWizardForm from './ReportWizardForm';

// Tab definitions
const tabs = [
    { label: 'Templates & Saved Reports', path: 'Dashboard', component: ReportDashboard },
    { label: 'Create/Edit Report', path: 'Edit', component: ReportWizardForm },
    { label: 'Facility Reports', path: 'Facility', component: FacilityReportManagement }
];

/**
 * The Report Module container component.
 *
 * @returns {React.ReactNode} the render component
 */
const ReportContainer = () => {
    const { path } = useRouteMatch();

    // On mount, log module load for tracking analytics
    useEffect(() => {
        document.title = 'HSPA - Reports';
        const analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'Reports' });
        analyticsObj.trackTabChange('Templates & Saved Reports');
    }, []);

    const tabPanes = _map(tabs, (tab) => {
        const routePath = tab.path ? `${path}/${tab.path}` : path;
        return <Route key={routePath} path={`${routePath}`} component={tab.component} />;
    });

    tabPanes.push(
        <Route
            key={path}
            path={path}
            exact
            render={() => <Redirect to={`${path}/${tabs[0].path}`} />}
        />
    );

    return (
        <div className="col-sm-12">
            <HeaderContainer pageName="Report" />
            <ModuleNavigation tabs={tabs} />
            <div style={{ marginTop: '15px' }}>
                <Switch>{tabPanes}</Switch>
            </div>
        </div>
    );
};

export default ReportContainer;

/**
 * Justification modal.
 * @author Anthony P. Pancerella
 * @version August 22, 2019
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '../../../../Lib/Button';

const MCDJustificationModal = React.memo(() => {
    const dispatch = useDispatch();
    const { type: calculationType } = useParams();

    const [justification, setJustification] = useState('');

    const showModal = useSelector((state) => state.PlannerModel.JustificationModal.show);
    const planItem = useSelector((state) => state.PlannerModel.JustificationModal.planItem);

    useEffect(() => setJustification(planItem.Justification || ''), [planItem]);

    const saveJustification = useCallback(
        (justification, planItem, calculationType) => {
            dispatch.PlannerModel.addJustification({ justification, planItem, calculationType });
            handleHide();
        },
        [dispatch]
    );

    const handleHide = useCallback(() => {
        setJustification('');
        dispatch.PlannerModel.toggleJustificationModal({});
    }, [dispatch]);

    return (
        <div>
            <Modal size="lg" show={showModal} onHide={handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title tabIndex={0}>
                        {'Add/Edit Justification: ' + (planItem.Name || planItem.RowName || '')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <textarea
                            className="form-control"
                            value={justification}
                            onChange={(event) => setJustification(event.target.value)}
                            placeholder={'Enter Justification'}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ height: '55px' }}>
                    <div className="col-sm-12">
                        <div className="form-group" style={{ paddingTop: '20px' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    color="primary"
                                    type="button"
                                    label="Save Justification"
                                    onClick={() =>
                                        saveJustification(justification, planItem, calculationType)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default MCDJustificationModal;

import React from 'react';
import { Card, Col, ListGroup, ListGroupItem, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { Link } from 'react-router-dom';

import { map } from 'lodash';
import { CSSProperties } from 'styled-components';

import { getPlanName, Plan, shortenDescription } from 'Utilities/types';

import { Location } from 'history';

import {
    faCheckCircle,
    faClinicMedical,
    faHospital,
    faStethoscope,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateCapitalProjectButton from './CreateCapitalProjectButton';
import SelectPlanModal from './SelectPlanModal';

type Props = {
    hspc: string;
    plans: Plan[];
};

const linkStyle: CSSProperties = {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 'bold'
};

const cardStyle = {
    width: '30rem',
    border: '2px solid #eee',
    borderRadius: '3px',
    boxShadow: '4px 4px 6px grey'
};

/**
 * Component for displaying the Plan information in a Card format.
 *
 * @param props - The component properties.
 * @param props.hspc - The HSPC name.
 * @param props.plans - The list of plans.
 * @returns - The component.
 */
const PlanCard = ({ hspc, plans }: Props): JSX.Element => {
    const cardBody = map(plans, (p) => {
        const planName = getPlanName(p);
        const to = (location: Location) => `${location.pathname}/${p.Id}`;
        const button = p.IsSelected ? (
            <Link to={to} style={linkStyle}>
                {planName}&nbsp;
                <span className="text-success">
                    <FontAwesomeIcon icon={faCheckCircle} />
                </span>
            </Link>
        ) : (
            <Link to={to} style={linkStyle}>
                {planName}&nbsp;
            </Link>
        );

        if (!p.Description) {
            return (
                <ListGroupItem key={p.Id} style={{ backgroundColor: 'inherit', border: 'none' }}>
                    {button}
                </ListGroupItem>
            );
        }

        /**
         * Creates the Description tooltip.
         *
         * @param props - The Tool Tip properties.
         * @returns - The Tooltip.
         */
        const renderDescriptionTooltip = (props: OverlayInjectedProps) => (
            <Tooltip id={`Plan-Desc-${p.Id}`} {...props}>
                {p.Description}
            </Tooltip>
        );

        return (
            <ListGroupItem key={p.Id} style={{ backgroundColor: 'inherit', border: 'none' }}>
                <OverlayTrigger placement="left" overlay={renderDescriptionTooltip}>
                    {button}
                </OverlayTrigger>
                <p
                    className="mb-1"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {shortenDescription(p.Description, 50)}
                </p>
            </ListGroupItem>
        );
    });

    const renderCareTypeIcon = () => {
        const careType = plans[0].Hspc.MpcType;
        /**
         *
         */
        const careTypeTooltip = (props: OverlayInjectedProps) => (
            <Tooltip id={`Care-Type-${hspc}`} {...props}>
                {careType}
            </Tooltip>
        );

        let icon: IconDefinition;

        switch (careType) {
            case 'Outpatient':
                icon = faClinicMedical;
                break;
            case 'Inpatient':
                icon = faHospital;
                break;
            default:
                icon = faStethoscope;
                break;
        }

        return (
            <OverlayTrigger placement="right" overlay={careTypeTooltip}>
                <FontAwesomeIcon color="grey" icon={icon} style={{ fontSize: '1.5em' }} />
            </OverlayTrigger>
        );
    };

    const footer = (
        <div style={{ textAlign: 'center' }}>
            {plans.findIndex((plan) => plan.IsSelected === true) > -1 ? (
                <CreateCapitalProjectButton plans={plans} />
            ) : (
                <SelectPlanModal hspc={hspc} plans={plans} />
            )}
        </div>
    );

    return (
        <Card bg="light" text="dark" className="m-3" style={cardStyle}>
            <Card.Header tabIndex={0}>
                <Row>
                    <Col xs={1}>{renderCareTypeIcon()}</Col>
                    <Col xs={7}>
                        <h5>{hspc}</h5>
                    </Col>
                    <Col xs={4} className="text-sm-right">
                        {plans[0].CurrentHspcWorkloadTypeString}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body style={{ padding: '0px' }}>
                <ListGroup>{cardBody}</ListGroup>
            </Card.Body>
            <Card.Footer style={{ border: 'none', backgroundColor: 'white' }}>{footer}</Card.Footer>
        </Card>
    );
};

export default PlanCard;

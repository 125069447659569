import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

/**
 * @file The Ambulatory Care React modal for modifying capacity.
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import { getAmbulatoryCareSelector } from 'Modules/UnderstandCapacity/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { Form, formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form';

import { AmbulatoryCareCapacityColumnDefs } from './TableOutline';

/** The modal dialog for editing ambulatory care capacity. */
class EditAmbulatoryCareCapacityModal extends React.Component {
    static propTypes = {
        AmbulatoryCare: PropTypes.shape({
            list: PropTypes.arrayOf(
                PropTypes.shape({
                    WorkFTEE: PropTypes.number
                })
            )
        }),
        modal: PropTypes.shape({
            show: PropTypes.bool,
            saving: PropTypes.bool
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        currentFacility: PropTypes.string.isRequired,
        currentHspc: PropTypes.string.isRequired,
        marketName: PropTypes.string.isRequired,
        updateAmbulatoryCare: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        clearModal: PropTypes.func.isRequired,
        error: PropTypes.string.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        canEdit: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    static defaultProps = {
        AmbulatoryCare: { list: [] }
    };

    /**
     * Create an instance of EditAmbulatoryCareCapacityModal based on the initial set of props.
     *
     * @param {object} props - React properties passed into this component.
     */
    constructor(props) {
        super(props);
        this.state = { edits: {} };

        this.getCurrentTableData = this.getCurrentTableData.bind(this);
        this.handleMarketFacilityChange = this.handleMarketFacilityChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.updateRowCellValue = this.updateRowCellValue.bind(this);
    }

    /**
     * Get the current data to render to the table, prioritizing unsaved edits stored in state.
     *
     * @returns {object[]} the rows of data to render to the table
     */
    getCurrentTableData() {
        const { AmbulatoryCare, currentFacility, currentHspc } = this.props;
        const { edits } = this.state;

        return _map(
            _filter(AmbulatoryCare.list, {
                FacilityName: currentFacility,
                Hspc: currentHspc
            }),
            (row) => edits[row.Id] || row
        );
    }

    /**
     * When market or facility options change, remove all edits.
     */
    handleMarketFacilityChange() {
        this.setState({ edits: {} });
    }

    /**
     * Handle form submission by dispatching update actions.
     */
    formSubmit() {
        const { marketName, dispatch, updateAmbulatoryCare } = this.props;

        const { edits } = this.state;

        const data = this.getCurrentTableData();

        if (!_isEmpty(edits)) {
            updateAmbulatoryCare(data, marketName);
        } else {
            throw new SubmissionError({
                _error: 'Not saving, capacity values have not changed.'
            });
        }
        dispatch(reset('EditAmbulatoryCareCapacityModal'));

        this.setState({ edits: {} });
    }

    /**
     * When the modal is hidden, dispatch reset actions for the form state and reset any unsaved
     * values.
     */
    handleHide() {
        const { toggleModal, clearModal, dispatch } = this.props;
        dispatch(reset('EditAmbulatoryCareCapacityModal'));
        toggleModal();
        this.setState({ edits: {} });
        clearModal();
    }

    /**
     * Update the value of a row's cell.
     *
     * @param {number} oldValue - the previous value of the cell
     * @param {number} newValue - the new and current value of the cell
     * @param {object} row - the row of data for the updated cell
     * @param {object} column - column metadata for the updated cell
     */
    updateRowCellValue(oldValue, newValue, row, column) {
        const { edits } = this.state;

        if (Number(oldValue) !== Number(newValue)) {
            const dataField = column.dataField || column.id;
            const updatedRow = {
                ...row,
                [dataField]: Number(newValue)
            };
            updatedRow.Capacity = row.WorkFTEE * row.ActiveProductivityStandard;

            if (dataField === 'ActiveProductivityStandard') {
                updatedRow.EditableProductivityStandard = row.ActiveProductivityStandard;
            }

            this.setState({
                edits: {
                    ...edits,
                    [row.Id]: updatedRow
                }
            });
        }
    }

    /**
     * Render the ambulatory care modal.  Renders a pair of dropdowns and a table.
     *
     * @returns {React.ReactNode} the rendered modal
     */
    render() {
        const {
            AmbulatoryCare,
            currentFacility,
            modal,
            error,
            pristine,
            submitting,
            canEdit,
            handleSubmit,
            reset: resetForm
        } = this.props;

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.updateRowCellValue
        };

        return (
            <div>
                <Modal size="lg" show={modal.show} onHide={this.handleHide}>
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <Modal.Header closeButton>
                            <div tabIndex={0} className="modal-title">
                                <h4>HSPCs Specialty Care Capacity</h4>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <DropdownField
                                name="Facility"
                                label="Facility"
                                placeholder="Select facility..."
                                onChangeFunction={this.handleMarketFacilityChange}
                                options={_uniqBy(AmbulatoryCare.list, 'FacilityName')}
                                displayField="FacilityName"
                                valueField="FacilityName"
                            />
                            <DropdownField
                                name="HSPC"
                                label="HSPC"
                                placeholder="Select HSPC..."
                                onChangeFunction={this.handleMarketFacilityChange}
                                options={_uniqBy(
                                    _filter(
                                        AmbulatoryCare.list,
                                        (item) => item.FacilityName === currentFacility
                                    ),
                                    'Hspc'
                                )}
                                displayField="Hspc"
                                valueField="Hspc"
                            />
                            <ReactDataTable
                                keyValue="Id"
                                list={this.getCurrentTableData()}
                                columns={AmbulatoryCareCapacityColumnDefs(
                                    modal.saving,
                                    this.updateRowCellValue
                                )}
                                loading={false}
                                cellEditOptions={cellEditProp}
                                isExportable={false}
                                isEditable
                            />
                            {error && <strong style={{ color: 'red' }}>{error}</strong>}
                        </Modal.Body>
                        <Modal.Footer style={{ height: '55px' }}>
                            <div className="col-sm-12">
                                <div className="form-group" style={{ paddingTop: '20px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            label={modal.saving ? 'Saving...' : 'Save'}
                                            type="submit"
                                            color="success"
                                            disabled={!canEdit || pristine || submitting}
                                        />
                                        &nbsp;&nbsp;
                                        <Button
                                            label="Cancel"
                                            color="warning"
                                            type="button"
                                            disabled={pristine || submitting}
                                            onClick={resetForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const ReduxForm = reduxForm({
    form: 'EditAmbulatoryCareCapacityModal',
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};
        if (!values.HSPC) {
            errors.HSPC = 'Required';
        }
        if (!values.Facility) {
            errors.Facility = 'Required';
        }
        return errors;
    }
})(EditAmbulatoryCareCapacityModal);

/**
 * Create functions that dispatch actions to the redux store as props for this component.
 *
 * @param {Function} dispatch - used to dispatch actions to redux store
 * @returns {object} dispatching functions passed as a props object
 */
const mapDispatchToProps = (dispatch) => ({
    toggleModal: () => dispatch.InHouseCapacityModel.toggleModal('ambulatoryCareModal'),
    updateAmbulatoryCare: (formValues, market) =>
        dispatch.InHouseCapacityModel.saveAmbulatoryCareAsync({
            formValues,
            market
        }),
    clearModal: () => dispatch.InHouseCapacityModel.resetModalForm('ambulatoryCareModal')
});

const selector = formValueSelector('EditAmbulatoryCareCapacityModal');

/**
 * Map redux state to props for this component.
 *
 * @param {object} state - redux state
 * @returns {object} state-derived props to pass into this component
 */
const mapStateToProps = (state) => ({
    currentFacility: selector(state, 'Facility'),
    currentHspc: selector(state, 'HSPC'),
    initialValues: state.InHouseCapacityModel.ambulatoryCareModal.form || {},
    modal: state.InHouseCapacityModel.ambulatoryCareModal || {},
    marketName: getCurrentMarketName(state),
    AmbulatoryCare: getAmbulatoryCareSelector(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm);

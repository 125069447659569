import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select, { OnChangeValue } from 'react-select';
import { Option } from 'react-select/src/filters';

import { filter, map } from 'lodash';
import { useHSPASelector } from 'storeTypes';

import { createCapitalProject } from 'Modules/CapitalProject';
import { loadCapitalProjectTypes } from 'Modules/CapitalProjectType';
import {
    selectCapitalProjectTypes,
    selectCapitalProjectTypesLoadingStatus
} from 'Modules/CapitalProjectType/selectors';
import { loadFacilitySelection } from 'Modules/FacilitySelection';
import {
    selectFacilitySelection,
    selectFacilitySelectionLoadingStatus
} from 'Modules/FacilitySelection/selectors';
import { loadHspcSelections } from 'Modules/HspcSelection';
import {
    selectHspcSelections,
    selectHspcSelectionsLoadingStatus
} from 'Modules/HspcSelection/selectors';
import { ManagePlansModel } from 'Modules/MatchCapacityDemand/Services/ManagePlansModel';
import { loadPlanSelection } from 'Modules/PlanSelection';
import { getCurrentMarket, getCurrentVISN } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';
import { getPlanName, Market, NameId, Plan, PlanDTO, Visn } from 'Utilities/types';

type CreateCapitalProjectModalProps = {
    showModal: boolean;
    plan: PlanDTO;
    createFinish: () => void;
};

const CreateCapitalProjectModal = ({
    plan,
    showModal,
    createFinish
}: CreateCapitalProjectModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const market = useSelector(getCurrentMarket) as Market;
    const visn = useSelector(getCurrentVISN) as Visn;
    const managePlansModel = useHSPASelector((state) => state.ManagePlansModel);
    const facilities = useSelector(selectFacilitySelection);
    const types = useSelector(selectCapitalProjectTypes);
    const loadingTypeStatus = useSelector(selectCapitalProjectTypesLoadingStatus) as AsyncState;
    const loadingFacilityStatus = useSelector(selectFacilitySelectionLoadingStatus) as AsyncState;
    const loadingHspcSelectionStatus = useSelector(selectHspcSelectionsLoadingStatus) as AsyncState;
    const hspcSelections = useSelector(selectHspcSelections);

    const defaultStartingYear = 2025;

    const typeSelectOptions = map(
        types,
        (type): Option => ({ label: type.name, value: type.id, data: null })
    );

    const facilitySelectionOptions = map(
        facilities,
        (f): Option => ({ label: f.facility, value: f.facilityId, data: null })
    );

    const hspcSelectionOptions = map(
        hspcSelections,
        (hspc): Option => ({ label: hspc.name, value: hspc.id, data: null })
    );

    const defaultPlans: NameId[] =
        plan && plan.Plan ? [{ id: plan.Plan.Id.toString(), name: getPlanName(plan.Plan) }] : [];

    const [show, setShow] = useState<boolean>(showModal || false);
    const [facilityId, setFacilityId] = useState<string>();
    const [facility, setFacility] = useState<string>();
    const [typeId, setTypeId] = useState<number>();
    const [isDisposal, setIsDisposal] = useState<boolean>(false);
    const [startingYear, setStartingYear] = useState<number>(defaultStartingYear);
    const [completionYear, setCompletionYear] = useState<number>();
    const [description, setDescription] = useState<string>();
    const [defaultSpecialties, setDefaultSpecialties] = useState<Option[]>([]);
    const [hspcLinks, setHspcLinks] = useState<NameId[]>([]);
    const [planLinks, setPlanLinks] = useState<NameId[]>(defaultPlans);

    const handleClose = () => {
        setFacility(undefined);
        setFacilityId(undefined);
        setTypeId(undefined);
        setIsDisposal(false);
        setStartingYear(defaultStartingYear);
        setCompletionYear(undefined);
        setDescription(undefined);
        setHspcLinks([]);
        setPlanLinks([]);
        setShow(false);
        createFinish();
    };

    useEffect(() => {
        if (plan && plan.Plan) {
            const defaultOptions = filter(
                hspcSelectionOptions,
                (option) => parseInt(option.value, 10) === plan.Plan.HspcId
            );

            setDefaultSpecialties(defaultOptions);

            setHspcLinks(
                map(defaultOptions, (o): NameId => ({ id: o.value.toString(), name: o.label }))
            );

            setPlanLinks([{ id: plan.Plan.Id.toString(), name: getPlanName(plan.Plan) }]);
        } else {
            setDefaultSpecialties([]);
            setHspcLinks([]);
            setPlanLinks([]);
        }
    }, [plan]);

    useEffect(() => {
        if (showModal === true) {
            setPlanLinks(
                plan && plan.Plan
                    ? [{ id: plan.Plan.Id.toString(), name: getPlanName(plan.Plan) }]
                    : []
            );
        }
        setShow(showModal);
    }, [showModal, plan, plan.Plan]);

    useEffect(() => {
        dispatch(loadCapitalProjectTypes());
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadFacilitySelection(market.MarketName));
    }, [dispatch, market.MarketName]);

    useEffect(() => {
        dispatch(loadPlanSelection(market.MarketName));
    }, [dispatch, market.MarketName]);

    useEffect(() => {
        dispatch(loadHspcSelections());
    }, [dispatch]);

    useEffect(() => {
        if (ManagePlansModel?.Plans?.loading !== true) {
            const plans = managePlansModel.Plans.list as unknown as Plan[];
            const hspcIds = map(hspcLinks, (hspc: NameId) => parseInt(hspc.id, 10));
            const links = map(
                filter(plans, (p) => p.IsSelected === true && hspcIds.includes(p.HspcId)),
                (p): NameId => ({ id: p.Id.toString(), name: getPlanName(p) })
            );
            setPlanLinks(links);
        }
    }, [hspcLinks, managePlansModel.Plans, managePlansModel.Plans.loading]);

    const submitCapitalProject = () => {
        dispatch(
            createCapitalProject({
                visnNumber: visn.VisnNumber,
                marketID: market.Id,
                facilityId: facilityId || '',
                facility: facility || '',
                capitalProjectTypeID: typeId || 0,
                description: description || '',
                year: startingYear,
                completionYear,
                isDisposalProject: isDisposal || false,
                isSCIPProject: false,
                plans: planLinks || [],
                specialties: hspcLinks || []
            })
        );

        handleClose();
    };

    const onHspcSelectionChange = (value: OnChangeValue<Option, true>) => {
        if (value) {
            setHspcLinks(value.map<NameId>((h) => ({ name: h.label, id: h.value })));
        } else {
            setHspcLinks([]);
        }
    };

    const planListGroup = (): JSX.Element[] =>
        map(planLinks, (planItem: NameId) => (
            <ListGroup.Item key={planItem.id} as="li">
                {planItem.name}
            </ListGroup.Item>
        ));

    return (
        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header>
                <Modal.Title tabIndex={0}>Create a New Capital Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFacility">
                        <Form.Label>Parent Facility</Form.Label>
                        <Select
                            options={facilitySelectionOptions}
                            placeholder="Select the Parent Facility"
                            isLoading={loadingFacilityStatus === AsyncState.PENDING}
                            required
                            onChange={(value: OnChangeValue<Option, false>) => {
                                if (value) {
                                    setFacility(value.label);
                                    setFacilityId(value.value);
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="specialtySelections">
                        <Form.Label>Select Specialties</Form.Label>
                        <Select
                            options={hspcSelectionOptions}
                            placeholder="Select Specialties"
                            required
                            isMulti
                            isLoading={loadingHspcSelectionStatus === AsyncState.PENDING}
                            defaultValue={defaultSpecialties}
                            onChange={onHspcSelectionChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="planSelections">
                        <ListGroup>
                            <ListGroup.Item as="li" variant="success">
                                Linked Plans
                            </ListGroup.Item>
                            {planListGroup()}
                        </ListGroup>
                    </Form.Group>
                    <Form.Group controlId="formCapitalProjectType">
                        <Form.Label>Project Type</Form.Label>
                        <Select
                            options={typeSelectOptions}
                            placeholder="Select the Project Type"
                            isLoading={loadingTypeStatus === AsyncState.PENDING}
                            required
                            onChange={(value: OnChangeValue<Option, false>) => {
                                if (value) {
                                    setTypeId(parseInt(value.value, 10));
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formIsDisposalProject">
                        <Form.Check
                            type="switch"
                            label="Disposal Project"
                            checked={isDisposal}
                            onChange={(event) => setIsDisposal(event.target.checked)}
                        />
                    </Form.Group>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="formStartingYear">
                                <Form.Label>Starting Year</Form.Label>
                                <Form.Control
                                    as="input"
                                    type="number"
                                    min={defaultStartingYear}
                                    value={startingYear}
                                    onChange={(event) =>
                                        setStartingYear(parseInt(event.target.value, 10))
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formCompletionYear">
                                <Form.Label>Target Completion Year</Form.Label>
                                <Form.Control
                                    as="input"
                                    type="number"
                                    min={defaultStartingYear}
                                    value={completionYear}
                                    onChange={(event) =>
                                        setCompletionYear(parseInt(event.target.value, 10))
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Project Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={description}
                            required
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="rounded-pill" variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="rounded-pill" variant="primary" onClick={submitCapitalProject}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateCapitalProjectModal;
